import { AxiosResponse } from "axios";
import axiosClient from "./baseApi";
import LoginRequest from "../models/LoginRequest";
import LoginResponse from "../models/LoginResponse";

const authPath = {
  login: "/auth/login",
  logout: "logout",
  loginSocial: "login-social",
  forget: "forget",
  signup: "register",
  resetPassword: "users/update-password",
  validateResetPasswordToken: "reset-password",
  forgotPassword: "users/forgot-password",
  clipboardCopyForgot: "users/clipboard-copy-forgot",
  tokenAccess: "users/token-access",
  findLinkAll: "/auth/all-set-link",
  resetPasswordToken: "/users/verify-forgot-password-token",
};

export const login = (loginReq: LoginRequest) => {
  return axiosClient.post<AxiosResponse<LoginResponse>>(
    authPath?.login,
    loginReq,
  );
};

export const logout = (): Promise<AxiosResponse<any, any>> => {
  return axiosClient.get(authPath.logout);
};

export const findLinkAll = (): Promise<AxiosResponse<any, any>> => {
  return axiosClient.get(authPath.findLinkAll);
};

export const forgotPassword = (
  email: string,
): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
  };
  return axiosClient.get(authPath.forgotPassword, { params });
};
export const clipboardCopyForgot = (
  email: string,
): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
  };
  return axiosClient.get(authPath.clipboardCopyForgot, { params });
};

export const checkToken = (token: any): Promise<AxiosResponse<any, any>> => {
  const params = {
    token: token,
  };
  return axiosClient.get(authPath.tokenAccess, { params });
};
export const resetPassword = (data: any): Promise<AxiosResponse<any, any>> => {
  const params = {
    password: data?.password,
    token: data?.token,
  };
  return axiosClient.patch(`${authPath.resetPassword}`, params);
};

export const resetPasswordToken = ({
  token,
  email,
}: {
  email: string;
  token: string;
}): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
    token: token,
  };
  return axiosClient.get(authPath.resetPasswordToken, { params });
};
