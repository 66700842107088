/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from "../../../Layouts/PageHeader/PageHeader";
import {
  Col,
  Row,
  Form,
  Card,
  Tab,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import icoTitleRightAverageGmv from "../../../assets/images/icons/dashboard/ico-right-average-gmv.svg";
import icoTitleNetGmv from "../../../assets/images/icons/ico_dash_gmv.svg";
import icoBestPerformance from "../../../assets/images/icons/ico_best_performance.svg";
import icoTitleRightCompletedStoreVisits from "../../../assets/images/icons/dashboard/ico-right-completed-store-visits.svg";
import icoTitleQuestion from "../../../assets/images/icons/dashboard/ico-title-question.svg";
import icoSharp from "../../../assets/images/icons/icon/default/ico-sharp.svg";
import icoArrowRight from "../../../assets/images/icons/icon/default/vector-black.svg";
import icoSaleArrowRight from "../../../assets/images/icons/icon/default/chevron-right.svg";
import icoLocation from "../../../assets/images/icons/icon/default/ico-store.svg";
import icoRouteEfficiency from "../../../assets/images/icons/dashboard/ico-route-efficiency.svg";
import icoIncome from "../../../assets/images/icons/dashboard/income.svg";
import icoCost from "../../../assets/images/icons/dashboard/cost.svg";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  WeekOptions,
  DashboardDateRange,
} from "../../../Data/Pages/SelectOptions/DataSelect";
import { useEffect, useState } from "react";
import moment from "moment";
import { TIME_SVG } from "../../../Data/DataDashBoard/DashBoardData";
import {
  // DashboardQueryKey,
  getCompareDashBoard,
  getCurrentDashBoard,
} from "../../../services/dashboardApi";
import "moment-timezone";
import {
  ComparePercentage,
  EncryptedId,
  FORMAT_DATE_WITH_TIME,
  START_END_OF_WEEK_DATE,
  moneyFormat,
  moneyFormatWithDollarSince,
  percentFormat,
} from "../../../utils/global";
import imgDefault from "../../../assets/images/default-avatar-circle.png";
import LoadingSkeletonDashboard from "../../../Layouts/LoadingSkeletonDashboard/LoadingSkeletonDashboard";
import { useAuthen } from "../../../authen/AuthProvider";

Chart.register(...registerables);
interface IChartDataPoint {
  label: string;
  value: number;
}

const defaultDataSet = [
  {
    data: [1],
    backgroundColor: ["#DDDDDD"], // Grey color
  },
];

export const EmptyData = {
  labels: [],
  datasets: [
    {
      label: "Loading Data",
      data: [],
      borderColor: "#05c3fb",
      backgroundColor: "#05c3fb",
      tension: 0.3,
      borderWidth: 3,
      pointRadius: 0,
    },
  ],
};

const sumPrice = (data: any) => {
  return data?.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  );
};

const mouseHoverShowPercent = {
  label: function (context: any) {
    let label = "";
    let price = context?.parsed || 0;

    const percentOf = (price * 100) / context?.dataset?.sum;

    if (context.parsed.y !== null) {
      label +=
        moneyFormatWithDollarSince(context?.parsed, true) +
        " : " +
        moneyFormatWithDollarSince(percentOf?.toFixed(2), false) +
        "%";
    }
    return label;
  },
};

const sortWithNewPrice = (listData: any) => {
  return listData
    ?.map((it: any) => {
      const priceFloat = it?.price ? parseFloat(it?.price) : 0;
      const buybackFloat = it?.buyback ? parseFloat(it?.buyback) : 0;

      const priceTmp = priceFloat - buybackFloat;
      const newPrice = parseFloat(priceTmp?.toFixed(2));

      const newData: any = {
        ...it,
        new_price: newPrice,
      };
      return newData;
    })
    ?.sort((a: any, b: any) => b?.new_price - a?.new_price);
};

const BoxNetGmv = (data: any) => {
  const gmvFloat = parseFloat(data?.netGmv);
  const gmv2Fixed = gmvFloat?.toFixed(2);

  const creditFloat = parseFloat(data?.credit);

  const totalGmvFloat = parseFloat(data?.totalGmv);
  const totalGmv2Fixed = totalGmvFloat?.toFixed(2);
  const totalPercentGmv = parseFloat(data?.netPercentGmv);
  const totalPercentGmv2Fixed = moneyFormatWithDollarSince(
    totalPercentGmv?.toFixed(2),
    false
  );
  const diverGmv = parseFloat(data?.totalPercentGmv);
  const NetDiverGmv = diverGmv?.toFixed(2);

  const buyBackFloat = parseFloat(data?.buyBack);
  const buyBack2Fixed = buyBackFloat?.toFixed(2);

  const totalCreditFinal = creditFloat - buyBackFloat;

  const calSuccessPercent = (val: number) => {
    if (val === 0) {
      return "zero";
    } else if (val <= 0) {
      return "danger";
    } else {
      return "success";
    }
  };

  let txtSinceLastWeek = "";
  switch (data?.dateRange?.value) {
    case 0:
      txtSinceLastWeek = "Since Last Week";
      break;
    case -1:
      txtSinceLastWeek = "Since Prior Week";
      break;
    case -2:
      txtSinceLastWeek = "Since Prior 2 Weeks";
      break;
    case 1:
      txtSinceLastWeek = "Since Prior Month";
      break;
    default:
      // Handle the default case if needed
      break;
  }

  return (
    <>
      <div className="d-flex">
        {/* Left */}
        <Card.Title className="card-title-dashboard">
          <span className="dashboard-head-card"> Net GMV</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                total value of all products sold on a platform before
                subtracting fees or credits
              </Tooltip>
            }
          >
            <img
              className="ico-question"
              src={icoTitleQuestion}
              alt="Distributal"
            />
          </OverlayTrigger>
        </Card.Title>
        {/* Right */}
        <div className="card-options">
          <img src={icoTitleNetGmv} alt="Distributal" />
        </div>
      </div>

      {/* Row 1 */}
      <Form.Group>
        <Row className="align-items-center">
          <Form.Label className="col-xl-7 col-md-7 dsb-form-net-gmv-top-left">
            <div className="dashboard-warp-card-net-gmv">
              <span className="txt-dota-since">$</span>
              <span className="txt-val-net-gmv">
                {moneyFormat(gmv2Fixed, true)}
              </span>
            </div>
            <div className="dsb-form-total-gmv-top-left">
              <span className="txt-left-box-average-gmv">Total GMV:</span>
            </div>
            <div className="dashboard-wrap-total-gmv">
              <span className="val-total-gmv">
                {moneyFormatWithDollarSince(totalGmv2Fixed, true)}
              </span>
              <span
                className={`dashboard-wrap-percen ${calSuccessPercent(
                  data?.totalPercentGmv
                )}`}
              >
                {diverGmv > 0 && "+"}
                {moneyFormatWithDollarSince(percentFormat(NetDiverGmv), false)}%
              </span>
            </div>
          </Form.Label>
          <Col xl={5} md={5} className="dsb-form-net-gmv-top-left-mobile">
            <div>
              <span
                className={`dashboard-wrap-percen ${calSuccessPercent(
                  data?.netPercentGmv
                )}`}
              >
                {totalPercentGmv > 0 && "+"}
                {totalPercentGmv2Fixed}%
              </span>
            </div>
            <div>
              <span className="txt-left-box-average-gmv">
                {txtSinceLastWeek}
              </span>
            </div>
            <div className="dsb-wrap-credit-top-left">
              <span className="txt-left-box-average-gmv">Credits:</span>
            </div>
            <div className="dashboard-wrap-credits-gmv">
              <div className="md-credit">
                <span className="val-credits">
                  {moneyFormatWithDollarSince(
                    totalCreditFinal?.toFixed(2),
                    true
                  )}
                </span>
              </div>
              <div className="md-credit">
                <span
                  className={`dashboard-wrap-percen ${calSuccessPercent(
                    data?.percentCredit
                  )}`}
                >
                  {moneyFormatWithDollarSince(
                    percentFormat(data?.percentCredit),
                    false
                  )}
                  %
                </span>
              </div>
            </div>
            <div>
              <span className="txt-left-box-average-gmv">Buybacks:</span>
            </div>
            <div className="dashboard-wrap-buyback">
              <div className="md-buyback">
                <span className="val-buyback">
                  {moneyFormatWithDollarSince(buyBack2Fixed, true)}
                </span>
              </div>
              <div className="md-buyback">
                <span
                  className={`dashboard-wrap-percen ${calSuccessPercent(
                    data?.buyBackPercent
                  )}`}
                >
                  {moneyFormatWithDollarSince(
                    percentFormat(data?.buyBackPercent),
                    false
                  )}
                  %
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

const TabsNetTopBrand = (dataBrand: any) => {
  const gmv = dataBrand?.dataBrand;
  const credit = dataBrand?.dataCreditsBrand;
  const creditBuyBack = dataBrand?.creditBuyBack;

  const chartsGmvFloat = parseFloat(gmv?.totalCharts);
  const chartsGmv = chartsGmvFloat?.toFixed(2);

  const chartsCreditsGmvFloat =
    parseFloat(credit?.totalCharts) - parseFloat(creditBuyBack);
  const chartsCreditsGmvTmp = chartsCreditsGmvFloat;
  const chartsCreditsGmv = chartsCreditsGmvTmp?.toFixed(2);

  const countGmv = gmv?.valueChart?.length > 0;
  const countCredit = credit?.valueChart?.length > 0;

  const data = {
    datasets:
      countGmv && gmv?.valueChart?.length !== 0 && gmv?.valueChart[0] !== 0
        ? [
            {
              data: gmv?.valueChart?.slice(0, 6),
              sum: sumPrice(gmv?.valueChart),
              backgroundColor: [
                "#357D7F",
                "#4EA195",
                "#8CDBB4",
                "#AEE6C6",
                "#D0F0DC",
                "#F0FAF3",
              ],
            },
          ]
        : defaultDataSet,
  };

  const options = {
    plugins: {
      legend: {
        // display: false,
      },
      tooltip: {
        enabled: countGmv,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  const dataSum = credit?.listChart
    ?.map((item: any) => {
      const new_price =
        (item?.price ? parseFloat(item?.price) : 0) -
        (item?.buyback ? parseFloat(item?.buyback) : 0);
      return new_price;
    })
    .sort((a: any, b: any) => b - a);

  const dataCredits = {
    datasets:
      countCredit &&
      credit?.valueChart?.length !== 0 &&
      credit?.valueChart[0] !== 0
        ? [
            {
              data: dataSum?.slice(0, 6),
              sum: sumPrice(dataSum),
              backgroundColor: [
                "#F05200",
                "#FC8934",
                "#FFB867",
                "#FFDE9B",
                "#FFF6CE",
                "#FEFAE8",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const TopGmvLeft = (data: any) => {
    const listGmv = data?.listGmv;

    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };
    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopGmvRight = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopCreditLeft = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopCreditRight = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const optionsCredits = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countCredit,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  return (
    <Col>
      <div className="row ">
        {/* chart Net Gmv */}
        <div className="col-xl-6 wrap-box-net-best chart-dashboard-left">
          <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoIncome}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Net GMV</span>
                <div className="txt-top-brand">TOP 6 Brands</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col
                  xl={12}
                  lg={6}
                  sm={12}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={data}
                    options={options}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                ></Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                ></Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvLeft listGmv={gmv?.listChart} />
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvRight listGmv={gmv?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>

        {/* chart credit right */}
        <div className="col-xl-6 wrap-box-credit-best">
          <Card.Body className="wrap-box-card-right dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoCost}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Credit</span>
                <div className="txt-top-brand">TOP 6 Brands</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col xl={12} lg={12} sm={12}>
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={dataCredits}
                    options={optionsCredits}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsCreditsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditLeft listCredits={credit?.listChart} />

                  <p className="val-buybacks mt-5">
                    <span className="txt-gmv-best">Buybacks:</span>
                    <span className="txt-per-gmv-best">
                      {moneyFormatWithDollarSince(
                        creditBuyBack ? creditBuyBack : 0,
                        true
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditRight listCredits={credit?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>
      </div>
    </Col>
  );
};

const TabsNetTopDriver = (dataDriver: any) => {
  const netGmv = dataDriver?.dataDriver;
  const credits = dataDriver?.dataCreditsDriver;
  const creditBuyBack = dataDriver?.creditBuyBack;

  const chartsGmvFloat = parseFloat(netGmv?.totalCharts);
  const chartsGmv = chartsGmvFloat?.toFixed(2);

  const chartsCreditsGmvFloat =
    parseFloat(credits?.totalCharts) - parseFloat(creditBuyBack);

  const chartsCreditsGmvTmp = chartsCreditsGmvFloat;
  const chartsCreditsGmv = chartsCreditsGmvTmp?.toFixed(2);

  const TopGmvLeft = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopGmvRight = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopCreditLeft = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);

    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const TopCreditRight = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);

    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const countGmv = netGmv?.valueChart?.length > 0;
  const countCredit = credits?.valueChart?.length > 0;

  // GMV
  const data = {
    datasets:
      countGmv &&
      netGmv?.valueChart?.length !== 0 &&
      netGmv?.valueChart[0] !== 0
        ? [
            {
              data: netGmv?.valueChart?.slice(0, 6),
              sum: sumPrice(netGmv?.valueChart),
              backgroundColor: [
                "#357D7F",
                "#4EA195",
                "#8CDBB4",
                "#AEE6C6",
                "#D0F0DC",
                "#F0FAF3",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countGmv,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  // Credits
  // const dataCredit = credits?.valueChart
  //   ?.sort((a: any, b: any) => b - a)
  //   .slice(0, 6);

  const dataSum = credits?.listChart
    ?.map((item: any) => {
      const new_price =
        (item?.price ? parseFloat(item?.price) : 0) -
        (item?.buyback ? parseFloat(item?.buyback) : 0);
      return new_price;
    })
    .sort((a: any, b: any) => b - a);

  const dataCredits = {
    datasets:
      countCredit &&
      credits?.valueChart?.length !== 0 &&
      credits?.valueChart[0] !== 0
        ? [
            {
              data: dataSum?.slice(0, 6),
              sum: sumPrice(dataSum),
              backgroundColor: [
                "#F05200",
                "#FC8934",
                "#FFB867",
                "#FFDE9B",
                "#FFF6CE",
                "#FEFAE8",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };
  const optionsCredits = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countCredit,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  return (
    <Col>
      <div className="row ">
        {/* chart Net Gmv */}
        <div className="col-xl-6 wrap-box-net-best chart-dashboard-left">
          <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoIncome}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Net GMV</span>
                <div className="txt-top-brand">TOP 6 Driver</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col
                  xl={12}
                  lg={6}
                  sm={12}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={data}
                    options={options}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvLeft listGmv={netGmv?.listChart} />
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvRight listGmv={netGmv?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>

        {/* chart credit right */}
        <div className="col-xl-6 wrap-box-credit-best">
          <Card.Body className="wrap-box-card-right dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoCost}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Credit</span>
                <div className="txt-top-brand">TOP 6 Driver</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col xl={12} lg={12} sm={12}>
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={dataCredits}
                    options={optionsCredits}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsCreditsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditLeft listCredits={credits?.listChart} />
                  <p className="val-buybacks mt-5">
                    <span className="txt-gmv-best">Buybacks:</span>
                    <span className="txt-per-gmv-best">
                      {moneyFormatWithDollarSince(
                        creditBuyBack ? creditBuyBack : 0,
                        true
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditRight listCredits={credits?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>
      </div>
    </Col>
  );
};

const TabsNetTopRetailer = (dataRetailer: any) => {
  const netGmv = dataRetailer?.dataRetailer;
  const credits = dataRetailer?.dataCreditsRetailer;
  const creditBuyBack = dataRetailer?.creditBuyBack;

  const chartsGmvFloat = parseFloat(netGmv?.totalCharts);
  const chartsGmv = chartsGmvFloat?.toFixed(2);

  const chartsCreditsGmvFloat =
    parseFloat(credits?.totalCharts) - parseFloat(creditBuyBack);
  const chartsCreditsGmvTmp = chartsCreditsGmvFloat;
  const chartsCreditsGmv = chartsCreditsGmvTmp?.toFixed(2);

  const TopGmvLeft = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopGmvRight = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditLeft = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditRight = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const countGmv = netGmv?.valueChart?.length > 0;
  const countCredit = credits?.valueChart?.length > 0;

  const data = {
    datasets:
      countGmv &&
      netGmv?.valueChart?.length !== 0 &&
      netGmv?.valueChart[0] !== 0
        ? [
            {
              data: netGmv?.valueChart?.slice(0, 6),
              sum: sumPrice(netGmv?.valueChart),
              backgroundColor: [
                "#357D7F",
                "#4EA195",
                "#8CDBB4",
                "#AEE6C6",
                "#D0F0DC",
                "#F0FAF3",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countGmv,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  const dataSum = credits?.listChart
    ?.map((item: any) => {
      const new_price =
        (item?.price ? parseFloat(item?.price) : 0) -
        (item?.buyback ? parseFloat(item?.buyback) : 0);
      return new_price;
    })
    .sort((a: any, b: any) => b - a);

  const dataCredits = {
    datasets:
      countCredit &&
      credits?.valueChart?.length !== 0 &&
      credits?.valueChart[0] !== 0
        ? [
            {
              data: dataSum?.slice(0, 6),
              sum: sumPrice(dataSum),
              backgroundColor: [
                "#F05200",
                "#FC8934",
                "#FFB867",
                "#FFDE9B",
                "#FFF6CE",
                "#FEFAE8",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const optionsCredits = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countCredit,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };
  return (
    <Col>
      <div className="row ">
        {/* chart Net Gmv */}
        <div className="col-xl-6 wrap-box-net-best chart-dashboard-left">
          <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoIncome}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Net GMV</span>
                <div className="txt-top-brand">TOP 6 Retailer</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col
                  xl={12}
                  lg={6}
                  sm={12}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={data}
                    options={options}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvLeft listGmv={netGmv?.listChart} />
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvRight listGmv={netGmv?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>

        {/* chart credit right */}
        <div className="col-xl-6 wrap-box-credit-best">
          <Card.Body className="wrap-box-card-right dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoCost}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Credit</span>
                <div className="txt-top-brand">TOP 6 Retailer</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col xl={12} lg={12} sm={12}>
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={dataCredits}
                    options={optionsCredits}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsCreditsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditLeft listCredits={credits?.listChart} />
                  <p className="val-buybacks mt-5">
                    <span className="txt-gmv-best">Buybacks:</span>
                    <span className="txt-per-gmv-best">
                      {moneyFormatWithDollarSince(
                        creditBuyBack ? creditBuyBack : 0,
                        true
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditRight listCredits={credits?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>
      </div>
    </Col>
  );
};

const TabsNetTopBanner = (dataBanner: any) => {
  const netGmv = dataBanner?.dataBanner;
  const credits = dataBanner?.dataCreditsBanner;
  const creditBuyBack = dataBanner?.creditBuyBack;

  const chartsGmvFloat = parseFloat(netGmv?.totalCharts);
  const chartsGmv = chartsGmvFloat?.toFixed(2);

  const chartsCreditsGmvFloat =
    parseFloat(credits?.totalCharts) - parseFloat(creditBuyBack);
  const chartsCreditsGmvTmp = chartsCreditsGmvFloat;
  const chartsCreditsGmv = chartsCreditsGmvTmp?.toFixed(2);

  const TopGmvLeft = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopGmvRight = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditLeft = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditRight = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);

    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const countGmv = netGmv?.valueChart?.length > 0;
  const countCredit = credits?.valueChart?.length > 0;

  const data = {
    datasets:
      countGmv &&
      netGmv?.valueChart?.length !== 0 &&
      netGmv?.valueChart[0] !== 0
        ? [
            {
              data: netGmv?.valueChart?.slice(0, 6),
              sum: sumPrice(netGmv?.valueChart),
              backgroundColor: [
                "#357D7F",
                "#4EA195",
                "#8CDBB4",
                "#AEE6C6",
                "#D0F0DC",
                "#F0FAF3",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countGmv,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  const dataSum = credits?.listChart
    ?.map((item: any) => {
      const new_price =
        (item?.price ? parseFloat(item?.price) : 0) -
        (item?.buyback ? parseFloat(item?.buyback) : 0);
      return new_price;
    })
    .sort((a: any, b: any) => b - a);

  const dataCredits = {
    datasets:
      countCredit &&
      credits?.valueChart?.length !== 0 &&
      credits?.valueChart[0] !== 0
        ? [
            {
              data: dataSum?.slice(0, 6),
              sum: sumPrice(dataSum),
              backgroundColor: [
                "#F05200",
                "#FC8934",
                "#FFB867",
                "#FFDE9B",
                "#FFF6CE",
                "#FEFAE8",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const optionsCredits = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countCredit,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };
  return (
    <Col>
      <div className="row ">
        {/* chart Net Gmv */}
        <div className="col-xl-6 wrap-box-net-best chart-dashboard-left">
          <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoIncome}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Net GMV</span>
                <div className="txt-top-brand">TOP 6 Banner</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col
                  xl={12}
                  lg={6}
                  sm={12}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={data}
                    options={options}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvLeft listGmv={netGmv?.listChart} />
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvRight listGmv={netGmv?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>

        {/* chart credit right */}
        <div className="col-xl-6 wrap-box-credit-best">
          <Card.Body className="wrap-box-card-right dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoCost}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Credit</span>
                <div className="txt-top-brand">TOP 6 Banner</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col xl={12} lg={12} sm={12}>
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={dataCredits}
                    options={optionsCredits}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsCreditsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditLeft listCredits={credits?.listChart} />
                  <p className="val-buybacks mt-5">
                    <span className="txt-gmv-best">Buybacks:</span>
                    <span className="txt-per-gmv-best">
                      {moneyFormatWithDollarSince(
                        creditBuyBack ? creditBuyBack : 0,
                        true
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditRight listCredits={credits?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>
      </div>
    </Col>
  );
};

const TabsNetTopStoreGroup = (dataStoreGroup: any) => {
  const netGmv = dataStoreGroup?.dataStoreGroup;
  const credits = dataStoreGroup?.dataCreditsStoreGroup;
  const creditBuyBack = dataStoreGroup?.creditBuyBack;

  const chartsGmvFloat = parseFloat(netGmv?.totalCharts);
  const chartsGmv = chartsGmvFloat?.toFixed(2);

  const chartsCreditsGmvFloat =
    parseFloat(credits?.totalCharts) - parseFloat(creditBuyBack);
  const chartsCreditsGmvTmp = chartsCreditsGmvFloat;
  const chartsCreditsGmv = chartsCreditsGmvTmp?.toFixed(2);

  const TopGmvLeft = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopGmvRight = (data: any) => {
    const listGmv = data?.listGmv;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          const priceFloat = parseFloat(row?.price);
          const price = priceFloat?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div className={`circle-gmv-best ${sequenceArr(index)}`}></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(row?.price ? price : 0, true)}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditLeft = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [0, 2, 4];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        0: "first",
        2: "third",
        4: "fifth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    const sortPriceMinusBuyBack = sortWithNewPrice(listGmv);
    return (
      <>
        {sortPriceMinusBuyBack?.map((row: any, index: number) => {
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.new_price ? row?.new_price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };
  const TopCreditRight = (data: any) => {
    const listGmv = data?.listCredits;
    const indicesToCheck: number[] = [1, 3, 5];

    const sequenceArr = (number: number) => {
      const sequenceMap: { [key: number]: string } = {
        1: "second",
        3: "fourth",
        5: "sixth",
      };
      return sequenceMap[number as keyof typeof sequenceMap] || "";
    };

    return (
      <>
        {listGmv?.map((row: any, index: number) => {
          // const priceFloat = parseFloat(row?.price);
          // const buybackFloat = parseFloat(row?.buyback);
          // const priceTmp = priceFloat - buybackFloat;
          // const price = priceTmp?.toFixed(2);
          if (indicesToCheck.includes(index)) {
            return (
              <div className="val-top-gmv" key={index}>
                <div
                  className={`circle-credit-best ${sequenceArr(index)}`}
                ></div>
                <span className="txt-gmv-best">{row?.name}</span>
                <span className="txt-per-gmv-best">
                  {moneyFormatWithDollarSince(
                    row?.price ? row?.price : 0,
                    true
                  )}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const countGmv = netGmv?.valueChart?.length > 0;
  const countCredit = credits?.valueChart?.length > 0;

  const data = {
    datasets:
      countGmv &&
      netGmv?.valueChart?.length !== 0 &&
      netGmv?.valueChart[0] !== 0
        ? [
            {
              data: netGmv?.valueChart?.slice(0, 6),
              sum: sumPrice(netGmv?.valueChart),
              backgroundColor: [
                "#357D7F",
                "#4EA195",
                "#8CDBB4",
                "#AEE6C6",
                "#D0F0DC",
                "#F0FAF3",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countGmv,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };

  const dataSum = credits?.listChart
    ?.map((item: any) => {
      const new_price =
        (item?.price ? parseFloat(item?.price) : 0) -
        (item?.buyback ? parseFloat(item?.buyback) : 0);
      return new_price;
    })
    .sort((a: any, b: any) => b - a);

  const dataCredits = {
    datasets:
      countCredit &&
      credits?.valueChart?.length !== 0 &&
      credits?.valueChart[0] !== 0
        ? [
            {
              data: dataSum?.slice(0, 6),
              sum: sumPrice(dataSum),
              backgroundColor: [
                "#F05200",
                "#FC8934",
                "#FFB867",
                "#FFDE9B",
                "#FFF6CE",
                "#FEFAE8",
              ],
              display: true,
            },
          ]
        : defaultDataSet,
  };

  const optionsCredits = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: countCredit,
        callbacks: mouseHoverShowPercent,
      },
    },
    cutout: "70%",
    maintainAspectRatio: true,
    responsive: true,
    width: 400, // Set the desired width
    height: 300, // Set the desired height
  };
  return (
    <Col>
      <div className="row ">
        {/* chart Net Gmv */}
        <div className="col-xl-6 wrap-box-net-best chart-dashboard-left">
          <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoIncome}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Net GMV</span>
                <div className="txt-top-brand">TOP 6 Store Group</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col
                  xl={12}
                  lg={6}
                  sm={12}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={data}
                    options={options}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvLeft listGmv={netGmv?.listChart} />
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopGmvRight listGmv={netGmv?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>

        {/* chart credit right */}
        <div className="col-xl-6 wrap-box-credit-best">
          <Card.Body className="wrap-box-card-right dsb-wrap-box-bottom-left">
            <div className="d-flex">
              {/* Left */}
              <Card.Title className="card-title-dashboard">
                <img
                  className="ico-prefix-net-gmv"
                  src={icoCost}
                  alt="Distributal"
                />
                <span className="dashboard-head-card">Credit</span>
                <div className="txt-top-brand">TOP 6 Store Group</div>
              </Card.Title>
              {/* Right */}
              <div className="card-options">
                <Link to="/invoice-sales-report/" className="lnk-sale-rep">
                  <span className="txt-sale-lnk">Sales Report</span>
                  <img src={icoSaleArrowRight} alt="Distributal" />
                </Link>
              </div>
            </div>

            {/* Row 1 */}
            <Form.Group>
              <Row className="align-items-center">
                <Col xl={12} lg={12} sm={12}>
                  <Doughnut
                    className="canvas-dsb-doughnut-top-brand"
                    data={dataCredits}
                    options={optionsCredits}
                  />
                  <div className="inner-val-top-gmv">
                    <div className="wrap-txt-total">
                      <span className="txt-total">Total:</span>
                    </div>
                    <div className="wrap-txt-val-total">
                      <span className="txt-val-total">
                        {moneyFormatWithDollarSince(chartsCreditsGmv, true)}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* data net gmv */}
            <Form.Group className="dsb-wrap-group-data-net-gmv">
              <Row className="align-items-start">
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditLeft listCredits={credits?.listChart} />
                  <p className="val-buybacks mt-5">
                    <span className="txt-gmv-best">Buybacks:</span>
                    <span className="txt-per-gmv-best">
                      {moneyFormatWithDollarSince(
                        creditBuyBack ? creditBuyBack : 0,
                        true
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  sm={6}
                  md={6}
                  className="col-wrap-dsb-chart-top-gmv"
                >
                  <TopCreditRight listCredits={credits?.listChart} />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </div>
      </div>
    </Col>
  );
};

const BoxAverageGmv = (data: any) => {
  const calSuccessPercent = (val: number) => {
    if (val === 0) {
      return "zero";
    } else if (val <= 0) {
      return "danger";
    } else {
      return "success";
    }
  };

  const FooterRouteEfficiency = () => {
    return (
      <>
        <div className="wrap-route-efficiency">
          <div className="d-flex d-xs-grid wrap-mobile-eff">
            {/* Left */}
            <div>
              <div className="title d-flex align-items-center gap-1">
                <img src={icoRouteEfficiency} alt="Distributal" />
                <span className="txt-route-rcy">Route Efficiency:</span>
              </div>
            </div>

            {/* Right */}
            <div className=" text-end wrap-route-rcy-right">
              <span className="me-2 data">
                {moneyFormatWithDollarSince(data?.avgMile, true)}
              </span>

              <span className="me-2 mile">/mile</span>

              <span
                className={`me-2 dashboard-wrap-percen ${calSuccessPercent(
                  data?.avgMilePercent
                )}`}
              >
                {data?.avgMilePercent > 0 && "+"}
                {moneyFormatWithDollarSince(
                  percentFormat(data?.avgMilePercent),
                  false
                )}
                %
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="d-flex">
        {/* Left */}
        <Card.Title className="card-title-dashboard">
          <span className="dashboard-head-card">Average GMV</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>average GMV by visits, stores, and drivers</Tooltip>
            }
          >
            <img
              className="ico-question"
              src={icoTitleQuestion}
              alt="Distributal"
            />
          </OverlayTrigger>
        </Card.Title>
        {/* Right */}
        <div className="card-options">
          <img src={icoTitleRightAverageGmv} alt="Distributal" />
        </div>
      </div>

      {/* Row 1 */}
      <Form.Group>
        <div className="align-items-center d-flex gap-4 justify-content-start w-100">
          <Form.Label className="txt-left-box-average-gmv">
            Per visit:
          </Form.Label>
          <div className="dsb-col-mobile-res">
            <span className="txt-right-box-average-gmv">
              {moneyFormatWithDollarSince(data?.avgVisit, true)}
            </span>
            <span className="box-money-red">
              <span
                className={`dashboard-wrap-percen danger ${calSuccessPercent(
                  data?.avgVisitPercent
                )}`}
              >
                {data?.avgVisitPercent > 0 && "+"}
                {moneyFormatWithDollarSince(
                  percentFormat(data?.avgVisitPercent),
                  false
                )}
                %
              </span>
            </span>
          </div>
        </div>
      </Form.Group>

      {/* Row 2: Per store */}
      <Form.Group>
        <div className="align-items-center  d-flex gap-4 justify-content-start w-100">
          <Form.Label className=" txt-left-box-average-gmv">
            Per store:
          </Form.Label>
          <div className="dsb-col-mobile-res">
            <span className="txt-right-box-average-gmv">
              {moneyFormatWithDollarSince(data?.avgStore, true)}
            </span>
            <span className="box-money-red">
              <span
                className={`dashboard-wrap-percen ${calSuccessPercent(
                  data?.avgStorePercent
                )} `}
              >
                {data?.avgStorePercent > 0 && "+"}
                {moneyFormatWithDollarSince(
                  percentFormat(data?.avgStorePercent),
                  false
                )}
                %
              </span>
            </span>
          </div>
        </div>
      </Form.Group>

      {/* Row 2: Per driver */}
      <Form.Group>
        <div className="align-items-center d-flex gap-4 justify-content-start w-100">
          <Form.Label className="txt-left-box-average-gmv">
            Per driver:
          </Form.Label>
          <div className="dsb-col-mobile-res">
            <span className="txt-right-box-average-gmv">
              {moneyFormatWithDollarSince(data?.avgDriver, true)}
            </span>
            <span className="box-money-green">
              <span
                className={`dashboard-wrap-percen ${calSuccessPercent(
                  data?.avgDriverPercent
                )} `}
              >
                {data?.avgDriverPercent > 0 && "+"}
                {moneyFormatWithDollarSince(
                  percentFormat(data?.avgDriverPercent),
                  false
                )}
                %
              </span>
            </span>
          </div>
        </div>
      </Form.Group>

      <FooterRouteEfficiency />
    </>
  );
};

const BoxCompletedStoreVisits = (data: any) => {
  const SinceLastWeek = (val: any) => {
    const sinceLastWeek = parseFloat(val.sinceLastWeek);

    const statusSuccess = (data: any) => {
      if (data === 0) {
        return "zero";
      } else if (data < 0) {
        return "danger";
      } else if (data > 0) {
        return "success";
      }
    };

    let txtSinceLastWeek = "";
    switch (data?.dateRange?.value) {
      case 0:
        txtSinceLastWeek = "Since Last Week";
        break;
      case -1:
        txtSinceLastWeek = "Since Prior Week";
        break;
      case -2:
        txtSinceLastWeek = "Since Prior 2 Weeks";
        break;
      case 1:
        txtSinceLastWeek = "Since Prior Month";
        break;
      default:
        // Handle the default case if needed
        break;
    }

    return (
      <div className="d-flex align-items-center wrap-completed-store">
        <div
          className={`d-flex box-money-red dashboard-wrap-percen ${statusSuccess(
            sinceLastWeek
          )}`}
        >
          <span className="">
            {sinceLastWeek > 0 && "+"}
            {moneyFormatWithDollarSince(sinceLastWeek, false)}%
          </span>
        </div>
        <div className="wrap-text-since">
          <span className="txt-since-time">{txtSinceLastWeek}</span>
        </div>
      </div>
    );
  };

  const DoughnutData = (val: any) => {
    const visits = val?.valueVisit;
    const visitsAll = val?.valueVisitAll - val?.valueVisit;
    const data = {
      datasets: [
        {
          data: [visits, visitsAll],
          backgroundColor: ["#7abaee", "#e3f7fe"],
          display: true,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      rotation: -90,
      circumference: 180,
      cutout: "85%",
      borderRadius: 10,
      maintainAspectRatio: true,
      responsive: true,
      width: 400, // Set the desired width
      height: 300, // Set the desired height
    };
    const complete = parseInt(val.complete);
    return (
      <>
        <div className="chart-container Chart">
          <Doughnut
            className="canvas-dsb-doughnut"
            data={data}
            options={options}
          />
        </div>

        <div className="wrap-box-percent-completed-store-visits">
          <div className="txt-percent-completed-store-visits">
            {complete}
            <span className="percent-store-visits">%</span>{" "}
          </div>

          <div className="wrap-txt-percent-success">
            <span className="txt-left-percent-success">{val?.valueVisit}</span>
            <span className="wrap-txt-right-percent-success">
              /{" "}
              <span className="txt-right-percent-success">
                {val?.valueVisitAll}
              </span>
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="d-flex">
        {/* Left */}
        <Card.Title className="card-title-dashboard">
          <span className="dashboard-head-card">Completed store visits</span>
          <img
            className="ico-question"
            src={icoTitleQuestion}
            alt="Distributal"
          />
        </Card.Title>
        {/* Right */}
        <div className="card-options">
          <img src={icoTitleRightCompletedStoreVisits} alt="Distributal" />
        </div>
      </div>

      <div className="mb-2 text-muted card-subtitle h6">
        <SinceLastWeek sinceLastWeek={data?.sinceLastWeek} />
      </div>

      <div className="wrap-half-progress text-center">
        <div>
          <DoughnutData
            valueVisit={data?.storeVisit}
            valueVisitAll={data?.storeVisitAll}
            complete={data?.complete}
          />
        </div>
      </div>
    </>
  );
};

const BestPerformance = (data: any) => {
  const insDrive = data?.dataTopDriver;
  const insStore = data?.dataTopStore;

  const statusSuccess = (data: any) => {
    if (data === 0) {
      return "zero";
    } else if (data < 0) {
      return "danger";
    } else if (data > 0) {
      return "success";
    }
  };

  const insGmvDriverFloat = parseFloat(insDrive?.value);
  const insGmvDriver = insGmvDriverFloat?.toFixed(2);

  const insGmvStoreFloat = parseFloat(insStore?.value);
  const insGmvStore = insGmvStoreFloat?.toFixed(2);

  let imgDriver =
    insDrive?.image_name && insDrive?.image_url
      ? `${insDrive?.image_url}/original/${insDrive?.image_name}`
      : imgDefault;

  let imgStore =
    insStore?.image_name && insStore?.image_url
      ? `${insStore?.image_url}/original/${insStore?.image_name}`
      : imgDefault;
  const insId = EncryptedId(insDrive?.id);
  return (
    <>
      <div className="d-flex">
        {/* Left */}
        <Card.Title className="card-title-dashboard">
          <img src={icoBestPerformance} alt="Distributal" />
          <span className="dashboard-head-card title-best-performance">
            Best Performance
          </span>
        </Card.Title>
      </div>
      <div className="dsh-info-box-bestper">
        {/* driver box */}
        <div className="driver-box">
          <div className="dashboard-wrap-best-driver">
            <div className="row">
              <div className="col-md-3">
                <div className="wrap-img-driver">
                  <img src={imgDriver} alt="Distributal" />
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <span className="txt-left-box-average-gmv">Driver</span>
                </div>
                <div className="wrap-txt-name-driver-dsh">
                  <span className="txt-name-driver-dsh">
                    {insDrive?.name ? insDrive?.name : "-"}
                  </span>
                </div>
                <div>
                  <span className="text-head-best-gmv">GMV: </span>
                  <span
                    className={`dashboard-wrap-percen ${statusSuccess(
                      insGmvDriver
                    )}`}
                  >
                    {moneyFormatWithDollarSince(
                      insDrive?.value ? insGmvDriver : 0,
                      true
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-driver-bar-bottom d-flex justify-content-between">
            <div className="wrap-location-bottom-dsh">
              <img src={icoSharp} alt="Distributal" />
              <label className="location-bottom-dsh">{`${
                insDrive?.label ? insDrive?.label : "-"
              }`}</label>
            </div>
            <div className="wrap-btn-menu-driver">
              <Link
                to={`/representative/edit/${insId}`}
                state={{ representative: insDrive?.id }}
                className="btn-menu-driver"
              >
                <img src={icoArrowRight} alt="Distributal" />
              </Link>
            </div>
          </div>
        </div>

        {/* store box */}
        <div className="stroe-box">
          <div className="dashboard-wrap-best-store">
            <div className="row">
              <div className="col-md-3">
                <div className="wrap-img-driver">
                  <img src={imgStore} alt="Distributal" />
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <span className="txt-left-box-average-gmv">Store</span>
                </div>
                <div className="wrap-txt-name-store-dsh">
                  <span className="txt-name-store-dsh">
                    {insStore?.name ? insStore?.name : "-"}
                  </span>
                </div>
                <div>
                  <span className="text-head-best-gmv">GMV: </span>
                  <span
                    className={`dashboard-wrap-percen ${statusSuccess(
                      insStore?.value
                    )}`}
                  >
                    {moneyFormatWithDollarSince(
                      insStore?.value ? insGmvStore : 0,
                      true
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-store-bar-bottom d-flex justify-content-between">
            <div className="wrap-location-bottom-dsh">
              <img src={icoLocation} alt="Distributal" />
              <label className="location-bottom-dsh">
                {insStore?.label ? insStore?.label : "-"}
              </label>
            </div>
            <div className="wrap-btn-menu-store">
              <Link
                to={`/store/edit/${EncryptedId(insStore?.id)}`}
                className="btn-menu-store"
              >
                <img src={icoArrowRight} alt="Distributal" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function Dashboard() {
  const { userInfo } = useAuthen();
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [rawData, setRawData]: any = useState();
  const [, setDateCompare]: any = useState();

  //Net GMV Group
  const [totalGMV, setTotalGMV] = useState("");
  const [totalGMVPercent, setTotalGMVPercent] = useState("");
  const [netGmv, setNetGmv] = useState("");
  const [netPercentGmv, setNetPercentGmv] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  const [percentCredit, setPercentCredit] = useState("");
  const [buybackGMV, setBuyBackGmv] = useState("");
  const [buybackPercent, setBuyBackPercent] = useState("");

  //Average GMV Group
  const [avgVisit, setAvgVisit] = useState("");
  const [avgVisitPercent, setAvgVisitPercent] = useState("");
  const [avgStore, setAvgStore] = useState("");
  const [avgStorePercent, setAvgStorePercent] = useState("");
  const [avgDriver, setAvgDriver] = useState("");
  const [avgDriverPercent, setAvgDriverPercent] = useState("");
  const [avgGmvMile, setAvgGmvMile] = useState("");
  const [avgGmvMilePercent, setAvgGmvMilePercent] = useState("");

  //complete store visits group
  const [visits, setVisits] = useState("");
  const [visitsAll, setVisitsAll] = useState("");
  const [sinceLastWeek, setSinceLastWeek] = useState("");
  const [completePercent, setCompletePercent] = useState("");

  //chartBrand
  const [chartBrand, setChartBrand]: any = useState(EmptyData);
  const [chartCreditsBrand, setChartCreditsBrand]: any = useState(EmptyData);

  //chartDriver
  const [chartDriver, setChartDriver]: any = useState(EmptyData);
  const [chartCreditsDriver, setChartCreditsDriver]: any = useState(EmptyData);

  //chartRetailer
  const [chartRetailer, setChartRetailer]: any = useState(EmptyData);
  const [chartCreditsRetailer, setChartCreditsRetailer]: any =
    useState(EmptyData);

  //chartBanner
  const [chartBanner, setChartBanner]: any = useState(EmptyData);
  const [chartCreditsBanner, setChartCreditsBanner]: any = useState(EmptyData);

  //chartStoreGroup
  const [chartStoreGroup, setChartStoreGroup]: any = useState(EmptyData);
  const [chartCreditsStoreGroup, setChartCreditsStoreGroup]: any =
    useState(EmptyData);

  // set data insights group
  const [insightsDriver, setInsightsDriver]: any = useState(EmptyData);
  const [insightsStore, setInsightsStore]: any = useState(EmptyData);

  const [, setTotalComplete] = useState(0);
  const [, setTotalDelivery] = useState(0);
  const [diffCredit, setDiffCredit] = useState(0);
  const [diffGMV, setDiffGMV] = useState(0);
  const [, setDiffComplete] = useState(0);
  const [, setDiffDelivery] = useState(0);
  const [, setBannerData]: any = useState(EmptyData);
  const [, setCompleteData]: any = useState(EmptyData);
  const [, setCreditData]: any = useState(EmptyData);
  const [, setGMVData]: any = useState(EmptyData);

  const [dateRange, setDateRange]: any = useState(WeekOptions[0]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [dataCalendarRange, setDataCalendarRange] = useState<Date[] | string>(
    START_END_OF_WEEK_DATE
  );

  const [filterOptions, setFilterOptions]: any = useState({
    from_date: moment(dataCalendarRange[0])
      ?.set({ hours: 0, minute: 0, second: 0 })
      .format(FORMAT_DATE_WITH_TIME),

    to_date: moment(dataCalendarRange[1])
      ?.set({ hours: 23, minute: 59, second: 59 })
      .format(FORMAT_DATE_WITH_TIME),

    store: "",
    brand: "",
    store_label: "",
    brand_label: "",
    store_group: "",
    product_name: "",
    time_zone: timeZone,
  });

  const [dashboardData, setDashboardData]: any = useState();
  const [compareData, setCompareData]: any = useState();
  const [isFetching, setIsFetching] = useState(true);

  const CalculateDashboardData = (
    filterParam: any,
    data: any,
    compareData: any
  ) => {
    setRawData(data);

    const isHasBothData = data && compareData;
    //Net Gmv Group
    const gmvTotal = data?.total_gmv;
    // const gmvTotalPercent = data?.total_gmv_percent;
    const gmvTotalPercent = isHasBothData
      ? ComparePercentage(
          data?.total_gmv,
          compareData?.total_gmv ?? 0
        ).toString()
      : "0";
    const gmvNetGmv = data?.net_gmv;
    const gmvNetPercentGmv = isHasBothData
      ? ComparePercentage(data.net_gmv, compareData.net_gmv ?? 0).toString()
      : "0";
    const credits = data?.credit_gmv;
    // const creditsPercent = data?.credit_gmv_percent;
    const creditsPercent = isHasBothData
      ? ComparePercentage(
          data.credit_gmv - data.credit_gmv_buyback,
          (compareData?.credit_gmv ?? 0) -
            (compareData?.credit_gmv_buyback ?? 0)
        ).toString()
      : "0";
    const buyBackGmv = data?.credit_gmv_buyback;
    // const buyBackPercent = data?.credit_gmv_buyback_percent;
    const buyBackPercent = isHasBothData
      ? ComparePercentage(
          data.credit_gmv_buyback,
          compareData.credit_gmv_buyback ?? 0
        ).toString()
      : "0";

    //Average GMV
    const avgVisitFloat = parseFloat(data?.avg_visit);
    const avgVisit = avgVisitFloat?.toFixed(2);
    // const avgVisitPercent = data?.avg_visit_percent;
    const avgVisitPercent = isHasBothData
      ? ComparePercentage(
          data?.avg_visit,
          compareData?.avg_visit ?? 0
        ).toString()
      : "0";
    const avgStoreFloat = parseFloat(data?.avg_store);
    const avgStore = avgStoreFloat?.toFixed(2);
    // const avgStorePercent = data?.avg_store_percent;
    const avgStorePercent = isHasBothData
      ? ComparePercentage(
          data?.avg_store,
          compareData?.avg_store ?? 0
        ).toString()
      : "0";
    const avgDriverFloat = parseFloat(data?.avg_driver);
    const avgDriver = avgDriverFloat?.toFixed(2);
    // const avgDriverPercent = data?.avg_driver_percent;
    const avgDriverPercent = isHasBothData
      ? ComparePercentage(
          data?.avg_driver,
          compareData?.avg_driver ?? 0
        ).toString()
      : "0";
    const gmvMileFloat = parseFloat(data?.gmv_mile);
    const gmvMile = gmvMileFloat?.toFixed(2);
    // const gmvMilePercent = data?.gmv_mile_percent;
    const gmvMilePercent = isHasBothData
      ? ComparePercentage(data?.gmv_mile, compareData?.gmv_mile ?? 0).toString()
      : "0";

    // completed store visits group
    const visit: any = data?.visit;
    const visitAll: any = data?.visit_all;
    // const sinceLastWeek: any = data?.avg_complate_percent;
    const sinceLastWeek: any = isHasBothData
      ? ((data?.avg_complate - compareData.avg_complate) * 100) /
        compareData.avg_complate
      : "0";
    const completePercent: any = data?.avg_complate;
    const filterFromDate: string = filterParam?.from_date;
    const filterToDate: string = filterParam?.to_date;
    setDateCompare({
      dateFrom: data?.compare_form_date,
      dateTo: data?.compare_to_date,
    });

    //chart NetGmv brands
    const chartGmv = data?.brand_net;
    const chartCredits = data?.brand_credit;
    const chartBrand = calculateNetGmvChart(chartGmv);
    const chartCreditsBrand = calculateNetGmvChart(chartCredits);

    //chart NetGmv Driver
    const chartDriverGmv = data?.driver_net;
    const chartDriverCredit = data?.driver_credit;
    const chartNetDriverGmv = calculateNetGmvChart(chartDriverGmv);
    const chartNetDriverCredit = calculateNetGmvChart(chartDriverCredit);

    //chart NetGmv Retailer
    const chartRetailerGmv = data?.retailer_net;
    const chartRetailerCredit = data?.retailer_credit;
    const chartNetRetailerGmv = calculateNetGmvChart(chartRetailerGmv);
    const chartNetRetailerCredit = calculateNetGmvChart(chartRetailerCredit);

    //chart NetGmv Banner
    const chartBannerGmv = data?.banner_net;
    const chartBannerCredit = data?.banner_credit;
    const chartNetBannerGmv = calculateNetGmvChart(chartBannerGmv);
    const chartNetBannerCredit = calculateNetGmvChart(chartBannerCredit);

    //chart NetGmv Store Group
    const chartStoreGroupGmv = data?.store_group_net;
    const chartStoreGroupCredit = data?.store_group_credit;
    const chartNetStoreGroupGmv = calculateNetGmvChart(chartStoreGroupGmv);
    const chartNetStoreGroupCredit = calculateNetGmvChart(
      chartStoreGroupCredit
    );

    const insightsDriver = data?.driver_net ? data?.driver_net[0] : "";
    const insightsStore = data?.store_gmv ? data?.store_gmv[0] : "";

    //Net Gmv Group Set
    setTotalGMV(gmvTotal);
    setTotalGMVPercent(gmvTotalPercent);
    setNetGmv(gmvNetGmv);
    setNetPercentGmv(gmvNetPercentGmv);
    setTotalCredit(credits);
    setPercentCredit(creditsPercent);
    setBuyBackGmv(buyBackGmv);
    setBuyBackPercent(buyBackPercent);

    //Average Gmv Group
    setAvgVisit(avgVisit);
    setAvgVisitPercent(avgVisitPercent);
    setAvgStore(avgStore);
    setAvgStorePercent(avgStorePercent);
    setAvgDriver(avgDriver);
    setAvgDriverPercent(avgDriverPercent);
    setAvgGmvMile(gmvMile);
    setAvgGmvMilePercent(gmvMilePercent);

    //complete store visits group
    setVisits(visit);
    setVisitsAll(visitAll);
    setSinceLastWeek(sinceLastWeek);
    setCompletePercent(completePercent);

    //brand
    setChartBrand(chartBrand);
    setChartCreditsBrand(chartCreditsBrand);

    //driver
    setChartDriver(chartNetDriverGmv);
    setChartCreditsDriver(chartNetDriverCredit);

    //retailer
    setChartRetailer(chartNetRetailerGmv);
    setChartCreditsRetailer(chartNetRetailerCredit);

    //banner
    setChartBanner(chartNetBannerGmv);
    setChartCreditsBanner(chartNetBannerCredit);

    //store group
    setChartStoreGroup(chartNetStoreGroupGmv);
    setChartCreditsStoreGroup(chartNetStoreGroupCredit);

    //insights group
    setInsightsDriver(insightsDriver);
    setInsightsStore(insightsStore);

    setTotalComplete(data?.total_completed_visits);
    setTotalDelivery(data?.total_delivery);
    setDiffGMV(diffGMV);
    setDiffCredit(diffCredit);
    setDiffComplete(data?.week?.totalCompletedVisits2Week);
    setDiffDelivery(data?.week?.totalDelivery2Week);

    // GMV
    calculateGmvChart(filterFromDate, filterToDate, data);

    // Complete Data
    calculateCompleteDataChart(filterFromDate, filterToDate, data);

    // Credit
    calculateCreditChart(filterFromDate, filterToDate, data);

    // Brand Delivery
    calculateBrandDeliveryChart(filterFromDate, filterToDate, data);

    if (!isFetching) {
      setLoadingDashboard(false);
    }
  };

  const transformChartData = (
    fromDate: string,
    toDate: string,
    data: IChartDataPoint[]
  ) => {
    const newChartData: IChartDataPoint[] = [];
    const datesInRange: string[] = [];
    const userTimeZone = moment.tz.guess();

    const startDate: Date = moment(fromDate).tz(userTimeZone).toDate();
    const endDate: Date = moment(toDate).tz(userTimeZone).toDate();
    let currentDate: Date = new Date(startDate);

    while (currentDate <= endDate) {
      const day: string = currentDate.getDate().toString().padStart(2, "0");
      const month: string = currentDate.toLocaleString("default", {
        month: "short",
      });
      const year = currentDate.getFullYear().toString().slice(-2);

      const dateString: string = `${day} ${month} ${year}`;
      datesInRange?.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    datesInRange?.forEach((date) => {
      if (!data?.some((chart: any) => chart?.label === date)) {
        const emptyObj: IChartDataPoint = { label: date, value: 0 };
        newChartData?.push(emptyObj);
      } else {
        const existObj: IChartDataPoint = data?.find(
          (d: any) => d?.label === date
        ) ?? { label: date, value: 0 };
        newChartData?.push(existObj);
      }
    });

    const res = newChartData?.map((obj: IChartDataPoint) => {
      return {
        label: obj?.label,
        value: obj?.value,
      };
    });

    return res;
  };

  const calculateDisplayChart = (data: any) => {
    const numPoints = data?.length;

    if (numPoints < 31) {
      return data?.map((item: any) => ({
        label: `${item?.label}`,
        value: item?.value,
      }));
    } else if (numPoints < 61) {
      // Group by week
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 91) {
      // Group by week (8 weeks)
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 121) {
      // Group by week (12 weeks)
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 366) {
      // Group by month
      let currentMonthLabel = null;
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numPoints; i++) {
        const item = data[i];
        const monthLabel = item?.label.slice(3, 9); // Assuming the label is in the format "01 Jan 23"

        if (currentMonthLabel !== monthLabel) {
          if (currentMonthLabel !== null) {
            newData.push({ label: currentMonthLabel, value: valueSum });
          }
          currentMonthLabel = monthLabel;
          valueSum = item?.value; // Start with the current item's value
        } else {
          valueSum += item?.value; // Add the item's value to the current month's sum
        }

        if (i === numPoints - 1) {
          newData.push({ label: currentMonthLabel, value: valueSum });
        }
      }

      return newData;
    } else {
      // Group by year
      let currentYearLabel = null;
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numPoints; i++) {
        const item = data[i];
        const yearLabel = item?.label.slice(-2); // Assuming the label is in the format "01 Jan 23"

        if (currentYearLabel !== yearLabel) {
          if (currentYearLabel !== null) {
            newData.push({ label: `20${currentYearLabel}`, value: valueSum });
          }
          currentYearLabel = yearLabel;
          valueSum = item?.value; // Start with the current item's value
        } else {
          valueSum += item?.value; // Add the item's value to the current year's sum
        }

        if (i === numPoints - 1) {
          newData.push({ label: `20${currentYearLabel}`, value: valueSum });
        }
      }

      return newData;
    }
  };

  //Net Gmv Top
  const calculateNetGmvChart = (data: any) => {
    let arrChart: number[] = [];
    let sumChart: number = 0;
    let listByChart: any[] = [];

    data?.map((row: any) => {
      const valueFloat = parseFloat(row?.value);
      const value2Fixed = valueFloat?.toFixed(2);

      const res = row?.value ?? 0;

      arrChart.push(parseFloat(res?.toFixed(2)));

      sumChart += value2Fixed ? parseFloat(value2Fixed) : 0;

      const listChart = {
        name: row?.name ? row?.name : "",
        price: row?.value ? row?.value : "",
        buyback: row?.buyback ? row?.buyback : 0,
      };
      listByChart.push(listChart);

      return null;
    });

    const chartData = {
      valueChart: arrChart,
      totalCharts: sumChart,
      listChart: listByChart,
    };
    return chartData;
  };
  // 1. GMV
  const calculateGmvChart = (fromDate: string, toDate: string, data: any) => {
    const gmvChart: IChartDataPoint[] =
      data?.data_gmv?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transformChartData(fromDate, toDate, gmvChart);
    const transformData = calculateDisplayChart(newChartData);

    const gmvData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "GMV",
          data: transformData?.map((data: any) => data?.value),
          borderColor: "#05c3fb",
          backgroundColor: "#05c3fb",
          tension: 0.3,
          borderWidth: 3,
          pointRadius: 0,
        },
      ],
    };
    setGMVData(gmvData);
  };

  // 2. Complete Visit
  const calculateCompleteDataChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const completeChart: IChartDataPoint[] =
      data?.data_completed_visits?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transformChartData(fromDate, toDate, completeChart);
    const transformData = calculateDisplayChart(newChartData);

    const completeData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Credit Visit",
          data: transformData?.map((data: any) => data?.value),
          borderColor: "#05c3fb",
          backgroundColor: "#05c3fb",
          tension: 0.3,
          borderWidth: 3,
          pointRadius: 0,
        },
      ],
    };
    setCompleteData(completeData);
  };

  // 3. Credit
  const calculateCreditChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const creditChart: IChartDataPoint[] =
      data?.data_credit?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transformChartData(fromDate, toDate, creditChart);
    const transformData = calculateDisplayChart(newChartData);

    const creditData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Credits",
          data: transformData?.map((data: any) => data?.value),
          backgroundColor: "#fbf0cc",
          borderRadius: 18,
        },
      ],
    };

    setCreditData(creditData);
  };

  // 4. Delivery
  const calculateBrandDeliveryChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const brandChart: IChartDataPoint[] =
      data?.data_delivery?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const brandRow = {
      labels: brandChart?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Banner Delivery",
          backgroundColor: "#add5b7",
          data: brandChart?.map((data: any) => data?.value),
          borderRadius: 18,
        },
      ],
    };

    setBannerData(brandRow);
  };

  const TimeReset = () => {
    const [timeReset, setTimeReset] = useState({ hour: 0, min: 0, sec: 0 });
    useEffect(() => {
      setTimeReset({ hour: 0, min: 0, sec: 0 });
      let dateOne = moment(rawData?.ckeck_since);
      const interval = setInterval(() => {
        let dateTwo = moment();
        let resultSecond = dateTwo.diff(dateOne, "second");
        let resultMinute = dateTwo.diff(dateOne, "minute");
        let resultHour = dateTwo.diff(dateOne, "hour");
        setTimeReset({
          hour: resultHour,
          min: resultMinute,
          sec: resultSecond,
        });
      }, 5000);
      return () => clearInterval(interval);
    }, [rawData]);

    return (
      <span className="time-data" id="ct">
        {timeReset.sec > 60
          ? timeReset.min > 60
            ? timeReset.hour + "hours ago"
            : timeReset.min + " minutes ago"
          : timeReset.sec + " Seconds ago"}
      </span>
    );
  };

  const onClickSvg = () => {
    setFilterOptions({ ...filterOptions });
  };
  const onSelectDate = async (date: any) => {
    setDateRange(WeekOptions[4]);
    setDataCalendarRange(date);
    setFilterOptions({
      ...filterOptions,
      from_date: date
        ? moment(date[0].$d)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format(FORMAT_DATE_WITH_TIME)
        : "",
      to_date: date
        ? moment(date[1].$d)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format(FORMAT_DATE_WITH_TIME)
        : "",
    });
  };

  const onSelectDateRange = async (data: any) => {
    let newDateFrom = new Date();
    let newDateTo = new Date();
    setDateRange(data);
    switch (data?.value) {
      case 0:
        newDateFrom = moment(newDateFrom)
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        newDateTo = moment(newDateFrom).endOf("weeks").add(1, "day").toDate();
        setDataCalendarRange([newDateFrom, newDateTo]);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format(FORMAT_DATE_WITH_TIME),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format(FORMAT_DATE_WITH_TIME),
        });
        break;
      case -1:
        newDateFrom = moment(newDateFrom)
          .subtract(1, "weeks")
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        newDateTo = moment(newDateTo)
          .subtract(1, "weeks")
          .endOf("weeks")
          .add(1, "day")
          .toDate();
        setDataCalendarRange([newDateFrom, newDateTo]);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format(FORMAT_DATE_WITH_TIME),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format(FORMAT_DATE_WITH_TIME),
        });
        break;
      case -2:
        newDateFrom = moment(newDateFrom)
          .subtract(2, "weeks")
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        newDateTo = moment(newDateTo)
          .subtract(1, "weeks")
          .endOf("weeks")
          .add(1, "day")
          .toDate();
        setDataCalendarRange([newDateFrom, newDateTo]);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format(FORMAT_DATE_WITH_TIME),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format(FORMAT_DATE_WITH_TIME),
        });
        break;
      case 1:
        newDateFrom = moment(newDateFrom).startOf("month").toDate();
        setDataCalendarRange([newDateFrom, newDateTo]);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format(FORMAT_DATE_WITH_TIME),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format(FORMAT_DATE_WITH_TIME),
        });
        break;
      default:
        break;
    }
  };

  const transformDataUpdateFilter: any = () => {
    const {
      from_date: nFromDate,
      to_date: nToDate,
      store: nStore,
      brand: nBrand,
      product_name: nProductName,
      store_group: nStoreGroup,
      time_zone: nTimeZone,
    } = filterOptions;

    const newObj = {
      from_date: nFromDate,
      to_date: nToDate,
      store: nStore?.value ?? "",
      brand: nBrand?.value ?? "",
      product_name: nProductName?.value ?? "",
      store_group: nStoreGroup?.value ?? "",
      time_zone: nTimeZone,
    };

    return newObj;
  };

  useEffect(() => {
    setLoadingDashboard(true);
    setIsFetching(true);
    setDashboardData(null);
    setCompareData(null);
    const controller = new AbortController();
    const signal = controller.signal;
    getCurrentDashBoard(transformDataUpdateFilter(), signal)
      .then((response) => {
        if (response.status === 200) {
          setDashboardData(response.data.data);
        }
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
      });
    getCompareDashBoard(transformDataUpdateFilter(), signal)
      .then((response) => {
        if (response.status === 200) {
          setCompareData(response.data.data);
        }
      })
      .catch((e) => {
        setIsFetching(false);
      });
    return () => {
      controller.abort();
    };
  }, [filterOptions]);

  useEffect(() => {
    setLoadingDashboard(true);
    if (dashboardData) {
      CalculateDashboardData(filterOptions, dashboardData, compareData);
    }
  }, [dashboardData, compareData]);

  return (
    <>
      <div className="row g-6">
        <div className="col-lg-3 col-sm-6">
          <div className="card card-border-shadow-primary h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded bg-label-primary">
                    <i className="ti ti-truck ti-28px"></i>
                  </span>
                </div>
                <h4 className="mb-0">42</h4>
              </div>
              <p className="mb-1">On route vehicles</p>
              <p className="mb-0">
                <span className="text-heading fw-medium me-2">+18.2%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6">
          <div className="card card-border-shadow-warning h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded bg-label-warning">
                    <i className="ti ti-alert-triangle ti-28px"></i>
                  </span>
                </div>
                <h4 className="mb-0">8</h4>
              </div>
              <p className="mb-1">Vehicles with errors</p>
              <p className="mb-0">
                <span className="text-heading fw-medium me-2">-8.7%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6">
          <div className="card card-border-shadow-danger h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded bg-label-danger">
                    <i className="ti ti-git-fork ti-28px"></i>
                  </span>
                </div>
                <h4 className="mb-0">27</h4>
              </div>
              <p className="mb-1">Deviated from route</p>
              <p className="mb-0">
                <span className="text-heading fw-medium me-2">+4.3%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6">
          <div className="card card-border-shadow-info h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded bg-label-info">
                    <i className="ti ti-clock ti-28px"></i>
                  </span>
                </div>
                <h4 className="mb-0">13</h4>
              </div>
              <p className="mb-1">Late vehicles</p>
              <p className="mb-0">
                <span className="text-heading fw-medium me-2">-2.5%</span>
                <small className="text-muted">than last week</small>
              </p>
            </div>
          </div>
        </div>

        <div className="col-xxl-6 order-2">
          <div className="card h-100 card-border-shadow-primary">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="mb-1">Delivery Performance</h5>
                <p className="card-subtitle">12% increase in this month</p>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-text-secondary rounded-pill text-muted border-0 p-2 me-n1 waves-effect waves-light"
                  type="button"
                  id="deliveryPerformance"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical ti-md text-muted"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="deliveryPerformance"
                >
                  <Link className="dropdown-item waves-effect" to="">
                    Select All
                  </Link>
                  <Link className="dropdown-item waves-effect" to="">
                    Refresh
                  </Link>
                  <Link className="dropdown-item waves-effect" to="">
                    Share
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="p-0 m-0">
                <li className="d-flex mb-6">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-primary">
                      <i className="ti ti-package ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Packages in transit</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ti ti-chevron-up mb-1 me-1"></i>
                        25.8%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">10k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-info">
                      <i className="ti ti-truck ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">
                        Packages out for delivery
                      </h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ti ti-chevron-up mb-1 me-1"></i>
                        4.3%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">5k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-success">
                      <i className="ti ti-circle-check ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Packages delivered</h6>
                      <small className="text-danger fw-normal d-block">
                        <i className="ti ti-chevron-down mb-1 me-1"></i>
                        12.5%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">15k</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-warning">
                      <i className="ti ti-percentage ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Delivery success rate</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ti ti-chevron-up mb-1 me-1"></i>
                        35.6%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">95%</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex mb-6">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-secondary">
                      <i className="ti ti-clock ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Average delivery time</h6>
                      <small className="text-danger fw-normal d-block">
                        <i className="ti ti-chevron-down mb-1 me-1"></i>
                        2.15%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">2.5 Days</h6>
                    </div>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="avatar flex-shrink-0 me-4">
                    <span className="avatar-initial rounded bg-label-danger">
                      <i className="ti ti-users ti-26px"></i>
                    </span>
                  </div>
                  <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div className="me-2">
                      <h6 className="mb-0 fw-normal">Customer satisfaction</h6>
                      <small className="text-success fw-normal d-block">
                        <i className="ti ti-chevron-up mb-1 me-1"></i>
                        5.7%
                      </small>
                    </div>
                    <div className="user-progress">
                      <h6 className="text-body mb-0">4.5/5</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-xxl-6 order-2">
          <div className="card h-100 card-border-shadow-primary">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title m-0 me-2 pt-1 mb-2 d-flex align-items-center">
                <i className="ti ti-list-details me-3"></i> Activity Timeline
              </h5>
              <div className="dropdown">
                <button
                  className="btn btn-text-secondary rounded-pill text-muted border-0 p-2 me-n1 waves-effect waves-light"
                  type="button"
                  id="timelineWapper"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical ti-md text-muted"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="timelineWapper"
                >
                  <Link className="dropdown-item waves-effect" to="">
                    Download
                  </Link>
                  <Link className="dropdown-item waves-effect" to="">
                    Refresh
                  </Link>
                  <Link className="dropdown-item waves-effect" to="">
                    Share
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body pb-xxl-0">
              <ul className="timeline mb-0">
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-primary"></span>
                  <div className="timeline-event">
                    <div className="timeline-header mb-3">
                      <h6 className="mb-0">12 Invoices have been paid</h6>
                      <small className="text-muted">12 min ago</small>
                    </div>
                    <p className="mb-2">
                      Invoices have been paid to the company
                    </p>
                    <div className="d-flex align-items-center mb-1">
                      <div className="badge bg-lighter rounded-3">
                        <img
                          src="../../assets//img/icons/misc/pdf.png"
                          alt="img"
                          width="15"
                          className="me-2"
                        />
                        <span className="h6 mb-0 text-body">invoices.pdf</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-success"></span>
                  <div className="timeline-event">
                    <div className="timeline-header mb-3">
                      <h6 className="mb-0">Client Meeting</h6>
                      <small className="text-muted">45 min ago</small>
                    </div>
                    <p className="mb-2">Project meeting with john @10:15am</p>
                    <div className="d-flex justify-content-between flex-wrap gap-2">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="avatar avatar-sm me-2"></div>
                        <div>
                          <p className="mb-0 small fw-medium">
                            Lester McCarthy (Client)
                          </p>
                          <small>CEO of Pixinvent</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="timeline-item timeline-item-transparent">
                  <span className="timeline-point timeline-point-info"></span>
                  <div className="timeline-event">
                    <div className="timeline-header mb-3">
                      <h6 className="mb-0">Create a new project for client</h6>
                      <small className="text-muted">2 Day Ago</small>
                    </div>
                    <p className="mb-2">6 team members in a project</p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap p-0">
                        <div className="d-flex flex-wrap align-items-center">
                          <ul className="list-unstyled users-list d-flex align-items-center avatar-group m-0 me-2">
                            <li
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              className="avatar pull-up"
                              aria-label="Vinnie Mostowy"
                              data-bs-original-title="Vinnie Mostowy"
                            ></li>
                            <li
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              className="avatar pull-up"
                              aria-label="Allen Rieske"
                              data-bs-original-title="Allen Rieske"
                            ></li>
                            <li
                              data-bs-toggle="tooltip"
                              data-popup="tooltip-custom"
                              data-bs-placement="top"
                              className="avatar pull-up"
                              aria-label="Julee Rossignol"
                              data-bs-original-title="Julee Rossignol"
                            ></li>
                            <li className="avatar">
                              <span
                                className="avatar-initial rounded-circle pull-up text-heading"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-original-title="3 more"
                              >
                                +3
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div className="page-first">
        {userInfo && (
          <PageHeader titles={`Hi, ${userInfo?.name}`} items={[""]} />
        )}
        <div className="sub-title-page-laptop sub-title-page-mobile">
          <p className="text-left span-let-see-going">
            Let's see how business is going.
          </p>

          <div className="wrap-dashboard-sub-title-right">
            <div className="dashboard-wrap-last-ref">
              <span className="dashboard-head-last-ref">
                Last data refresh:
              </span>
              <span className="dashboard-span-time-ago">
                <TimeReset />
              </span>
              <TIME_SVG onClickEvent={onClickSvg} />
            </div>

            <div className="wrap-filter-field-set dashboard-date-wrap">
              <span className="field-set-header">Date</span>
              <div className="dashboard-wrap-input-date">
                <Select
                  className="select-filter-items-dashboard-page select-filter-items-date custom-inner"
                  defaultValue={WeekOptions[0]}
                  value={dateRange}
                  isSearchable={false}
                  options={WeekOptions}
                  onChange={onSelectDateRange}
                  classNamePrefix="Select"
                  isDisabled={loadingDashboard}
                />
                <DashboardDateRange
                  loadingDashboard={loadingDashboard}
                  setDataCalendar={onSelectDate}
                  dataCalendar={dataCalendarRange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-section-wrapper mt-4">
        {/* <!-- ROW-1 --> */}
        {/* <Row>
          <Col lg={12} md={12} sm={12} xl={12}> */}
        <Row>
          {/* Net GMV */}

          <Col sm={12} md={6} xl={4}>
            <div className="card overflow-hidden wrap-box-card dsb-wrap-box-net-top-left">
              <Card.Body>
                {loadingDashboard || isFetching ? (
                  <LoadingSkeletonDashboard />
                ) : (
                  <BoxNetGmv
                    dateRange={dateRange}
                    netGmv={netGmv}
                    netPercentGmv={netPercentGmv}
                    credit={totalCredit}
                    percentCredit={percentCredit}
                    buyBack={buybackGMV}
                    buyBackPercent={buybackPercent}
                    totalGmv={totalGMV}
                    totalPercentGmv={totalGMVPercent}
                  />
                )}
              </Card.Body>
            </div>
          </Col>

          {/* Average GMV */}
          <Col sm={12} md={6} xl={4}>
            <div className="card overflow-hidden wrap-box-card dsb-wrap-box-avg-top-center">
              <Card.Body>
                {loadingDashboard || isFetching ? (
                  <LoadingSkeletonDashboard />
                ) : (
                  <>
                    <BoxAverageGmv
                      avgVisit={avgVisit}
                      avgVisitPercent={avgVisitPercent}
                      avgStore={avgStore}
                      avgStorePercent={avgStorePercent}
                      avgDriver={avgDriver}
                      avgDriverPercent={avgDriverPercent}
                      avgMile={avgGmvMile}
                      avgMilePercent={avgGmvMilePercent}
                    />
                  </>
                )}
              </Card.Body>
            </div>
          </Col>

          {/* Completed store visit */}
          <Col sm={12} md={12} xl={4}>
            <div className="card overflow-hidden wrap-box-card dsb-wrap-box-completed-top-right">
              <Card.Body>
                {loadingDashboard || isFetching ? (
                  <LoadingSkeletonDashboard />
                ) : (
                  <>
                    <BoxCompletedStoreVisits
                      dateRange={dateRange}
                      isFetching={isFetching}
                      storeVisit={visits}
                      storeVisitAll={visitsAll}
                      complete={completePercent}
                      sinceLastWeek={sinceLastWeek}
                    />
                  </>
                )}
              </Card.Body>
            </div>
          </Col>
        </Row>
        {/* </Col>
        </Row> */}

        {/* Row 2 */}
        <Row>
          <Col lg={12} md={12} sm={12} xl={12}>
            <Row>
              {/* Net GMV */}
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="tab-header tab-dsb">
                  <div className="panel">
                    <Nav
                      variant="pills"
                      className="panel-tabs nav-tabs panel-custom-header-dsh"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Brand</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Driver</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="brands">Retailer</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Banner</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Store Group</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>

                <Col className="tab-body p-0" lg={8} md={12} sm={12} xl={8}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {loadingDashboard || isFetching ? (
                        <Card.Body className="wrap-box-card-left dsb-wrap-box-bottom-left">
                          {" "}
                          <LoadingSkeletonDashboard />
                        </Card.Body>
                      ) : (
                        <>
                          <TabsNetTopBrand
                            creditBuyBack={rawData?.brand_buyback}
                            dataBrand={chartBrand}
                            dataCreditsBrand={chartCreditsBrand}
                          />
                        </>
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <TabsNetTopDriver
                        creditBuyBack={rawData?.driver_buyback}
                        dataDriver={chartDriver}
                        dataCreditsDriver={chartCreditsDriver}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="brands">
                      <TabsNetTopRetailer
                        creditBuyBack={rawData?.retailer_buyback}
                        dataRetailer={chartRetailer}
                        dataCreditsRetailer={chartCreditsRetailer}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <TabsNetTopBanner
                        creditBuyBack={rawData?.banner_buyback}
                        dataBanner={chartBanner}
                        dataCreditsBanner={chartCreditsBanner}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth">
                      <TabsNetTopStoreGroup
                        creditBuyBack={rawData?.store_group_buyback}
                        dataStoreGroup={chartStoreGroup}
                        dataCreditsStoreGroup={chartCreditsStoreGroup}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>

              {/* Completed store visit */}
              <Col lg={4} md={12} sm={12} xl={4}>
                <div className="card overflow-hidden wrap-box-card dsb-wrap-box-bottom-right">
                  <Card.Body>
                    {loadingDashboard || isFetching ? (
                      <LoadingSkeletonDashboard />
                    ) : (
                      <BestPerformance
                        dataTopDriver={insightsDriver}
                        dataTopStore={insightsStore}
                      />
                    )}
                  </Card.Body>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Dashboard;
