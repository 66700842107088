import { BrowserRouter, useLocation } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { createRoot, Root } from "react-dom/client";
import App from "./App";
import { useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";
import Error403 from "./components/Authentication/Errors/Forbidden/Index";

// Style
import "./index.css";

const container: HTMLElement | null = document.getElementById("root");

if (!container || !(container instanceof HTMLElement)) {
  throw new Error("Root container missing or not an HTMLElement");
}

const root: Root = createRoot(container);

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ErrorBoundary
      fallback={({ error }: any) => {
        if (/ChunkLoadError/.test(error.name)) {
          window.location.reload();
        }
        return <Error403 />;
      }}
    >
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
);

reportWebVitals();
