import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Preloader from "./Layouts/Loader/Preloader";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthenProvider, {
  RequireAuthen,
  RequirePermission,
} from "./authen/AuthProvider";
import "./index.css";

// *** Layout Components ***
const LayoutApp = lazy(() => import("./components/LayoutApp"));
const LayoutRouting = lazy(() => import("./components/LayoutRouting"));
const LayoutAuth = lazy(() => import("./components/LayoutAuth"));

// *** Global Components ***
const UnauthorizedError = lazy(
  () => import("./components/Authentication/Errors/Unauthorized/Index")
);
const ForbiddenError = lazy(
  () => import("./components/Authentication/Errors/Forbidden/Index")
);
const NotFoundError = lazy(
  () => import("./components/Authentication/Errors/NotFound/Index")
);
const ServiceUnavailableError = lazy(
  () => import("./components/Authentication/Errors/ServiceUnavailable/Index")
);
const BadRequestError = lazy(
  () => import("./components/Authentication/Errors/BadRequest/Index")
);
const InternalServerError = lazy(
  () => import("./components/Authentication/Errors/InternalServer/Index")
);
const ErrorPages = lazy(() => import("./components/ErrorPages"));

// *** Authentication Components ***
const Login = lazy(() => import("./components/Authentication/Login/Index"));
const ForgotPassword = lazy(
  () => import("./components/Authentication/Forgot Password/ForgotPassword")
);
const Profile = lazy(() => import("./components/pages/Profile/Profile"));

// *** Pages Components ***
const Dashboard = lazy(() => import("./components/pages/Dashboard/Dashboard"));
const Activity = lazy(() => import("./components/pages/Activity/Activity"));

// *** Customer Request Components ***
const CustomerRequestProductAdd = lazy(
  () => import("./components/pages/CustomerRequest/Product/Add")
);
const CustomerRequestProductEdit = lazy(
  () => import("./components/pages/CustomerRequest/Product/Edit")
);
const CustomerRequestPriceAdd = lazy(
  () => import("./components/pages/CustomerRequest/Price/ListAdd")
);
const CustomerRequestPriceEdit = lazy(
  () => import("./components/pages/CustomerRequest/Price/ListEdit")
);

// *** App Data Components ***
const FleetsOrdering = lazy(
  () => import("./components/pages/AppData/FleetsOrdering/index")
);
const FleetsOrderingEdit = lazy(
  () => import("./components/pages/AppData/FleetsOrdering/edit")
);
const FleetsOrderingCreate = lazy(
  () => import("./components/pages/AppData/FleetsOrdering/created")
);
const InvoiceList = lazy(
  () => import("./components/pages/InvoiceList/invoice_list")
);
const Invoice = lazy(() => import("./components/pages/AppData/Invoice/List"));
const InvoicesAdd = lazy(
  () => import("./components/pages/AppData/Invoice/InvoiceAdd/InvoiceAdd")
);
const Products = lazy(
  () => import("./components/pages/AppData/Products/Index")
);
const ProductsAdd = lazy(
  () => import("./components/pages/AppData/Products/Add")
);

const ProductsEdit = lazy(
  () => import("./components/pages/AppData/Products/Edit")
);
const Stores = lazy(() => import("./components/pages/AppData/Stores/Index"));
const StoresAdd = lazy(() => import("./components/pages/AppData/Stores/Add"));
const StoresEdit = lazy(() => import("./components/pages/AppData/Stores/Edit"));

const RoutesPage = lazy(
  () => import("./components/pages/AppData/Routes/Routes")
);

const RoutesWithoutGmvPage = lazy(
  () => import("./components/pages/AppData/RoutesWithoutGmv/Index")
);

const MasterRoute = lazy(
  () => import("./components/pages/AppData/MasterRoute/MasterRoute")
);
const RoutingPage = lazy(
  () => import("./components/pages/AppData/Routing/Routing")
);
const RoutingWithoutMap = lazy(
  () => import("./components/pages/AppData/RoutingWithoutMap/Index")
);
const RoutingWithoutMapAdd = lazy(
  () => import("./components/pages/AppData/RoutingWithoutMap/Add")
);
const RoutingWithoutMapEdit = lazy(
  () => import("./components/pages/AppData/RoutingWithoutMap/Edit")
);
const Representative = lazy(
  () => import("./components/pages/AppData/Representatives/Representatives")
);
const RepresentativeEdit = lazy(
  () => import("./components/pages/AppData/Representatives/Edit/Edit")
);
const RepresentativeAdd = lazy(
  () => import("./components/pages/AppData/Representatives/Add/Add")
);
const SaleOrders = lazy(
  () => import("./components/pages/AppData/SaleOrders/SaleOrders")
);
const SaleOrdersAdd = lazy(
  () =>
    import("./components/pages/AppData/SaleOrders/SaleOrdersAdd/SaleOrdersAdd")
);
const SaleOrdersEdit = lazy(
  () =>
    import(
      "./components/pages/AppData/SaleOrders/SaleOrdersEdit/SaleOrdersEdit"
    )
);
const InvoiceUploads = lazy(
  () => import("./components/pages/AppData/InvoiceUploads/InvoiceUploads")
);
const InvoiceUploadsEdit = lazy(
  () =>
    import(
      "./components/pages/AppData/InvoiceUploads/invoiceEdit/invoiceUploadsEdit"
    )
);
const InvoiceUploadsAdd = lazy(
  () =>
    import(
      "./components/pages/AppData/InvoiceUploads/invoiceAdd/invoiceUploadsAdd"
    )
);
const Temporary = lazy(
  () => import("./components/pages/AppData/Temporary/List")
);
const TemporaryAdd = lazy(
  () => import("./components/pages/AppData/Temporary/Add")
);
const TemporaryEdit = lazy(
  () => import("./components/pages/AppData/Temporary/Edit")
);
const TemporaryView = lazy(
  () => import("./components/pages/AppData/Temporary/View")
);
const Credit = lazy(() => import("./components/pages/AppData/Credit/Credit"));
const CreditsUploads = lazy(
  () => import("./components/pages/AppData/CreditUploads/CreditsUploads")
);
const CreditsUploadsAdd = lazy(
  () => import("./components/pages/AppData/CreditUploads/CreditsAdd/CreditsAdd")
);
const CreditsUploadsEdit = lazy(
  () =>
    import("./components/pages/AppData/CreditUploads/CreditsEdit/CreditEdit")
);
const PermanentStoreList = lazy(
  () => import("./components/pages/AppData/PermanentStoreAssignment/ListPage")
);
const PermanentStoreAdd = lazy(
  () => import("./components/pages/AppData/PermanentStoreAssignment/AddPage")
);
const PermanentStoreEdit = lazy(
  () => import("./components/pages/AppData/PermanentStoreAssignment/editPage")
);
const PermanentStoreView = lazy(
  () => import("./components/pages/AppData/PermanentStoreAssignment/viewPage")
);
const SaleHistoryView = lazy(
  () => import("./components/pages/AppData/SaleHistory/SaleHistory")
);

// *** Report Components ***
const TaskPhotoList = lazy(
  () => import("./components/pages/Report/TaskPhotos/List")
);
const TaskProofDeliveryReport = lazy(
  () => import("./components/pages/Report/TaskProof/TaskProof")
);
const TaskDeliveryReport = lazy(
  () => import("./components/pages/Report/TaskDelivery/TaskDelivery")
);
const GenInvoiceHistory = lazy(
  () => import("./components/pages/Report/GenInvoiceHistory/GenInvoiceHistory")
);
const FieldOrder = lazy(
  () => import("./components/pages/Report/InvoiceSales/List")
);
const StockAudit = lazy(
  () => import("./components/pages/Report/StockAudit/StockAudit")
);
const CreditReason = lazy(
  () => import("./components/pages/Report/Credits/Credits")
);
const CreditGenerateHistory = lazy(
  () => import("./components/pages/Report/GenInvoiceHistory/GenCreditsHistory")
);
const OrderPredictions = lazy(
  () => import("./components/pages/Report/OrderPrediction/OrderPrediction")
);
const BackStocks = lazy(
  () => import("./components/pages/Report/BackStock/BackStock")
);
const OutOfStocks = lazy(
  () => import("./components/pages/Report/OutOfStock/OutOfStock")
);
const UserVisits = lazy(
  () => import("./components/pages/Report/UserVisits/UserVisits")
);
const FleetPerformance = lazy(
  () => import("./components/pages/Report/FleetPerformance/FleetPerformance")
);
const ProductNotAuthorized = lazy(
  () =>
    import(
      "./components/pages/Report/ProductNotAuthorized/ProductNotAuthorized"
    )
);

// *** Setting Components ***
const RoleList = lazy(() => import("./components/pages/Setting/Role/List"));
const RoleAdd = lazy(() => import("./components/pages/Setting/Role/Add"));
const RoleEdit = lazy(() => import("./components/pages/Setting/Role/Edit"));

const CategoryList = lazy(
  () => import("./components/pages/Setting/Category/List")
);
const CategoryAdd = lazy(
  () => import("./components/pages/Setting/Category/Add")
);
const CategoryEdit = lazy(
  () => import("./components/pages/Setting/Category/Edit")
);

const BrandList = lazy(() => import("./components/pages/Setting/Brand/List"));
const BrandAdd = lazy(() => import("./components/pages/Setting/Brand/Add"));
const BrandEdit = lazy(() => import("./components/pages/Setting/Brand/Edit"));

// Section Fleets Setting
const FleetsList = lazy(() => import("./components/pages/Setting/Fleets/List"));
const FleetsAdd = lazy(() => import("./components/pages/Setting/Fleets/Add"));
const FleetsEdit = lazy(() => import("./components/pages/Setting/Fleets/Edit"));

// *** territory
const TerritoryList = lazy(
  () => import("./components/pages/Setting/Territories/List")
);
const TerritoryEdit = lazy(
  () => import("./components/pages/Setting/Territories/Edit")
);
const TerritoryCreate = lazy(
  () => import("./components/pages/Setting/Territories/Add")
);

// *** Inventory Components ***
const InventorySummary = lazy(
  () => import("./components/pages/Inventory/Summary/List")
);
const Warehouse = lazy(
  () => import("./components/pages/Inventory/Warehouse/Index")
);
const WarehouseAdd = lazy(
  () => import("./components/pages/Inventory/Warehouse/Add")
);
const WarehouseEdit = lazy(
  () => import("./components/pages/Inventory/Warehouse/Edit")
);
const WarehouseShow = lazy(
  () => import("./components/pages/Inventory/Warehouse/View/List")
);
const Vehicles = lazy(
  () => import("./components/pages/Inventory/Vehicles/Index")
);
const VehiclesCreate = lazy(
  () => import("./components/pages/Inventory/Vehicles/Add")
);
const VehiclesEdit = lazy(
  () => import("./components/pages/Inventory/Vehicles/Edit")
);

const VehiclesShow = lazy(
  () => import("./components/pages/Inventory/Vehicles/View/List")
);

const PurchaseOrder = lazy(
  () => import("./components/pages/Inventory/PurchaseOrder/List")
);
const PurchaseAdd = lazy(
  () => import("./components/pages/Inventory/PurchaseOrder/PurchaseAdd")
);
const PurchaseEdit = lazy(
  () => import("./components/pages/Inventory/PurchaseOrder/PurchaseEdit")
);
const PurchaseReceive = lazy(
  () => import("./components/pages/Inventory/PurchaseOrder/PurchaseReceive")
);
const Transfer = lazy(
  () => import("./components/pages/Inventory/Transfer/List")
);
const TransferAdd = lazy(
  () => import("./components/pages/Inventory/Transfer/Add")
);

const InventoryOrdering = lazy(
  () => import("./components/pages/Inventory/Ordering/DataVehicles")
);
const InventoryOrderingNew = lazy(
  () => import("./components/pages/Inventory/OrderingNew/DataVehicles")
);
const OrdersLogsReport = lazy(
  () => import("./components/pages/Inventory/Ordering/Orerlog/index")
);
const DefaultOrder = lazy(
  () => import("./components/pages/Inventory/DefaultOrder/ListDefault")
);
const InventoryOrderingCreate = lazy(
  () => import("./components/pages/Inventory/Ordering/Create")
);
const InventoryOrderingEdit = lazy(
  () => import("./components/pages/Inventory/Ordering/EditNew")
);
const InventoryOrderingApprove = lazy(
  () => import("./components/pages/Inventory/Ordering/ApproveOrder")
);
const InventoryOrderingShow = lazy(
  () => import("./components/pages/Inventory/Ordering/ShowOrder")
);

const ReportInventoryVehicles = lazy(
  () => import("./components/pages/Inventory/Report/DataReportVehicles")
);

const ReportInventoryWarehouse = lazy(
  () => import("./components/pages/Inventory/Report/DataReportWarehouse")
);
const TaskGroupsList = lazy(
  () => import("./components/pages/Setting/TaskGroups/List")
);
const TaskGroupsAdd = lazy(
  () => import("./components/pages/Setting/TaskGroups/Add")
);
const TaskGroupsEdit = lazy(
  () => import("./components/pages/Setting/TaskGroups/Edit")
);

const ChangeLog = lazy(() => import("./components/pages/ChangeLog/changeLog"));

/* --- Message --- */
const Chats = lazy(() => import("./components/pages/Message/Chats/index"));
// const DynamicChats = lazy(
//   () => import("./components/pages/Message/DynamicChats/index")
// );
const BroadCast = lazy(
  () => import("./components/pages/Message/Broadcast/Broadcast")
);

//tasks
const Tasklist = lazy(() => import("./components/pages/Setting/Task/List"));
const TaskAdd = lazy(() => import("./components/pages/Setting/Task/Add"));
const TaskEdit = lazy(() => import("./components/pages/Setting/Task/Edit"));

//retailer
const RetailerList = lazy(
  () => import("./components/pages/Setting/Retailer/List")
);
const RetailerAdd = lazy(
  () => import("./components/pages/Setting/Retailer/Add")
);
const RetailerEdit = lazy(
  () => import("./components/pages/Setting/Retailer/Edit")
);

//stores group
const StoreGroupsAdd = lazy(
  () => import("./components/pages/Setting/StoreGroups/Add")
);
const StoreGroupsEdit = lazy(
  () => import("./components/pages/Setting/StoreGroups/Edit")
);

const BannerList = lazy(() => import("./components/pages/Setting/Banner/List"));
const BannerAdd = lazy(() => import("./components/pages/Setting/Banner/Add"));
const BannerEdit = lazy(() => import("./components/pages/Setting/Banner/Edit"));
const StoreGroupsList = lazy(
  () => import("./components/pages/Setting/StoreGroups/List")
);
const StoreSchedules = lazy(
  () => import("./components/pages/Report/StoreSchedules/StoreSchedules")
);

const DeviceLog = lazy(
  () => import("./components/pages/Report/DeviceLog/DeviceLog")
);
const StorePerformance = lazy(
  () => import("./components/pages/Report/StorePerformance/StorePerformance")
);
const StockAdjustmentsWarehouse = lazy(
  () => import("./components/pages/Inventory/Warehouse/View/StockAdjustments")
);
const StockAdjustmentsVehicle = lazy(
  () => import("./components/pages/Inventory/Vehicles/View/StockAdjustments")
);

const NewDrivers = lazy(
  () => import("./components/pages/AppData/NewDrivers/NewDrivers")
);

const CreateMasterRoute = lazy(
  () => import("./components/pages/AppData/MasterRoute/Create/Create")
);
const AddMasterRoute = lazy(
  () => import("./components/pages/AppData/MasterRoute/Create/AddStore")
);
const EditMasterRoute = lazy(
  () => import("./components/pages/AppData/MasterRoute/Edit/Edit.tsx")
);
const EditAddMasterRoute = lazy(
  () => import("./components/pages/AppData/MasterRoute/Edit/AddStore")
);
const ResetPassword = lazy(
  () => import("./components/Authentication/ResetPassword/ResetPassword")
);
const TokenExpire = lazy(
  () => import("./components/Authentication/PageExpire/pageExpire")
);
const AllSet = lazy(() => import("./components/Authentication/AllSet/allSet"));
const ResetSuccessfully = lazy(
  () =>
    import("./components/Authentication/ResetSuccessfully/resetSuccessfully")
);
const Activation = lazy(
  () => import("./components/Authentication/Activate/Index")
);

const DeliveryPartner = lazy(
  () => import("./components/Register/DeliveryPartner")
);

const ThankYou = lazy(() => import("./components/Register/ThankYou"));

// *** Others Components ***
const ToolsPage = lazy(() => import("./components/pages/Tools/"));
const GeofencingPage = lazy(
  () => import("./components/pages/Tools/Geofencing")
);
const ClearDataPage = lazy(
  () => import("./components/pages/Tools/ClearData/Index")
);

// *** React Query ***
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
    },
  },
});

// *** Storage ***
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
}).then();

// *** Permission ***
const roleAdmin = parseInt(process.env.REACT_APP_ROLE_ADMIN);
const roleDriver = parseInt(process.env.REACT_APP_ROLE_DRIVER);
const roleFleet = parseInt(process.env.REACT_APP_ROLE_FLEET);

const permissionAdmin = [roleAdmin, roleDriver, roleFleet];
const permissionAdminSecond = [roleAdmin, roleFleet, roleDriver];

const App = () => {
  // *** UI ***
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer stacked />
      <Suspense fallback={<Preloader />}>
        <AuthenProvider>
          <React.Suspense fallback={<Preloader />}>
            <Routes>
              <Route
                path={"/"}
                element={
                  <RequireAuthen>
                    <LayoutApp />
                  </RequireAuthen>
                }
              >
                {/* =====  Menu Dashboard */}
                <Route
                  path={"/"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <Dashboard />
                    </RequirePermission>
                  }
                />
                <Route path={"/routing"} element={<RoutingPage />} />
                <Route
                  path={"/routing/:id/:name/:place/:day"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <RoutingPage />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/dashboard"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <Dashboard />
                    </RequirePermission>
                  }
                />
                {/* ===== Menu Data */}
                <Route
                  path={"/master-route"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <MasterRoute />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/routing-without-map"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <RoutingWithoutMap />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/routing-without-map/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <RoutingWithoutMapAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/routing-without-map/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <RoutingWithoutMapEdit />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/routes"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <RoutesPage />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/routes-without-gmv"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <RoutesWithoutGmvPage />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/"}
                  element={
                    <RequireAuthen>
                      <LayoutRouting />
                    </RequireAuthen>
                  }
                ></Route>

                <Route
                  path={"/temporary/schedule"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <Temporary />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/temporary/schedule/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <TemporaryAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/temporary/schedule/edit/:id/:date"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <TemporaryEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/temporary/schedule/view/:id/:date"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <TemporaryView />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/permanent-store-list"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <PermanentStoreList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/permanent-store-list/add"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <PermanentStoreAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/permanent-store-list/view/:id"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <PermanentStoreView />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/permanent-store-list/edit/:id"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <PermanentStoreEdit />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/stores"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <Stores />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store/edit"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <StoresEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <StoresEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store/edit/:id/:page"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <StoresEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <StoresAdd />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/new-drivers/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <NewDrivers />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/new-drivers"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <NewDrivers />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/representatives"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <Representative />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/representative/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <RepresentativeEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/representative/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <RepresentativeAdd />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/products"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <Products />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/product/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <ProductsAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/product/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <ProductsEdit />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/sales-orders"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <SaleOrders />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/sales-orders/create"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <SaleOrdersAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/sales-orders/edit/:placeId/:saleId"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <SaleOrdersEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/invoices"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <Invoice />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/invoices/list/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <InvoicesAdd />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"invoice-upload/"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <InvoiceUploads />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/invoice-upload/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <InvoiceUploadsEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/invoice-upload/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <InvoiceUploadsAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/credits"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <Credit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/credits-upload"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <CreditsUploads />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/credits-upload/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <CreditsUploadsEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/credits-upload/add"}
                  element={
                    <RequirePermission
                      requiredPermission={permissionAdminSecond}
                    >
                      <CreditsUploadsAdd />
                    </RequirePermission>
                  }
                />

                {/* =====  Menu Reports */}
                <Route
                  path={"/reports/task-photos"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <TaskPhotoList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/task-poof-of-delivery"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <TaskProofDeliveryReport />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/delivery"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <TaskDeliveryReport />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/stock-audit"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <StockAudit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/credits"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <CreditReason />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/credits/generate-log/credit/:id"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <CreditGenerateHistory />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/order-prediction"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <OrderPredictions />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/reports/invoice-sales/"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <FieldOrder />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/invoice-sales/generate-log/invoice/:id"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <GenInvoiceHistory />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/back-stock"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <BackStocks />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/out-of-stock"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <OutOfStocks />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/user-visits"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <UserVisits />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/store-schedules"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <StoreSchedules />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/fleet-performance"}
                  element={<FleetPerformance />}
                />
                <Route
                  path={"/reports/device-logs"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <DeviceLog />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/product-not-authorized"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <ProductNotAuthorized />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/profile"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <Profile />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/activities"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <Activity />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/request/request-add"}
                  element={
                    <RequirePermission requiredPermission={[roleFleet]}>
                      <CustomerRequestPriceAdd />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/invoice/list-old"}
                  element={
                    <RequirePermission requiredPermission={[roleFleet]}>
                      <InvoiceList />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/fleet-ordering"}
                  element={
                    <RequirePermission requiredPermission={[roleFleet]}>
                      <FleetsOrdering />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/fleet-ordering/edit/:id"}
                  element={
                    <RequirePermission requiredPermission={[roleFleet]}>
                      <FleetsOrderingEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/fleet-ordering/create"}
                  element={
                    <RequirePermission requiredPermission={[roleFleet]}>
                      <FleetsOrderingCreate />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/sales-history"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <SaleHistoryView />
                    </RequirePermission>
                  }
                />

                {/* =====  Menu Customer Request */}
                <Route
                  path={"/customer-request/add-products"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CustomerRequestProductAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/customer-request/edit-products"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CustomerRequestProductEdit />
                    </RequirePermission>
                  }
                />

                {/* Custom Requests: Price */}
                <Route
                  path={"/customer-request/add-prices"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CustomerRequestPriceAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/customer-request/edit-prices"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CustomerRequestPriceEdit />
                    </RequirePermission>
                  }
                />

                {/* =====  Menu Settings */}
                {/* Tools */}
                <Route
                  path={"/tools"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <ToolsPage />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/tools/geofencing"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <GeofencingPage />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/tools/clear-data"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <ClearDataPage />
                    </RequirePermission>
                  }
                />

                {/* Brands */}
                <Route
                  path={"/brands"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <BrandList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/brand/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <BrandAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/brand/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleDriver, roleFleet]}
                    >
                      <BrandEdit />
                    </RequirePermission>
                  }
                />

                {/* Fleets */}
                <Route
                  path={"/fleets"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <FleetsList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/fleets/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <FleetsAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/fleets/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <FleetsEdit />
                    </RequirePermission>
                  }
                />

                {/*  territory */}
                <Route
                  path={"/territories"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TerritoryList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/territory/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TerritoryCreate />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/territory/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TerritoryEdit />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/task-groups"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TaskGroupsList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/task-groups/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TaskGroupsEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/task-groups/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TaskGroupsAdd />
                    </RequirePermission>
                  }
                />

                {/*tasks */}
                <Route
                  path={"/tasks"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <Tasklist />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/task/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TaskAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/task/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TaskEdit />
                    </RequirePermission>
                  }
                />

                {/*Role  */}
                <Route
                  path={"/roles"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RoleList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/role/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RoleAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/role/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RoleEdit />
                    </RequirePermission>
                  }
                />

                {/*Category  */}
                <Route
                  path={"/categories"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CategoryList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/category/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CategoryAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/category/edit/:categoryId"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <CategoryEdit />
                    </RequirePermission>
                  }
                />

                {/* retailer */}
                <Route
                  path={"/retailers"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RetailerList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/retailer/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RetailerAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/retailer/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <RetailerEdit />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/banners"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <BannerList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/banner/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <BannerAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/banner/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <BannerEdit />
                    </RequirePermission>
                  }
                />

                {/* store group */}
                <Route
                  path={"/store-groups"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StoreGroupsList />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store-group/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StoreGroupsAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/store-group/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StoreGroupsEdit />
                    </RequirePermission>
                  }
                />

                {/* Inventory */}
                <Route
                  path={"/inventory-summary"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventorySummary />
                    </RequirePermission>
                  }
                />
                {/* warehouses */}
                <Route
                  path={"/warehouses"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <Warehouse />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/warehouses/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <WarehouseAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/warehouses/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <WarehouseEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/warehouses/show/:id/:name"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <WarehouseShow />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/stock-adjustment"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StockAdjustmentsWarehouse />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/reports/inventory-of-warehouses"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <ReportInventoryWarehouse />
                    </RequirePermission>
                  }
                />

                {/* vehicles */}
                <Route
                  path={"/vehicles"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <Vehicles />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/vehicles/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <VehiclesCreate />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/vehicles/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <VehiclesEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/vehicles/show/:id/:name"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <VehiclesShow />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/reports/inventory-of-vehicles"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <ReportInventoryVehicles />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/stock-adjustment-v/:id/:name"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StockAdjustmentsVehicle />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/purchase-orders"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <PurchaseOrder />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/purchase-orders/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <PurchaseAdd />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/purchase-orders/receive"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <PurchaseReceive />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/purchase-orders/edit/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <PurchaseEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/transfer"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <Transfer />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/transfer/add"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <TransferAdd />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/ordering"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrdering />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/ordering-new"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrderingNew />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/default-order"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <DefaultOrder />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/ordering-log-report/:id/:orderId"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <OrdersLogsReport />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/inventory-ordering/create"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrderingCreate />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/inventory-ordering/edit/:id/:warehouse_id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrderingEdit />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/inventory-ordering/approve/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrderingApprove />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/inventory-ordering/show/:id"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <InventoryOrderingShow />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/store-performance"}
                  element={
                    <RequirePermission
                      requiredPermission={[roleAdmin, roleFleet, roleDriver]}
                    >
                      <StorePerformance />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/chats"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      {/* <DynamicChats /> */}
                      <Chats />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/broadcast-message"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <BroadCast />
                    </RequirePermission>
                  }
                />

                <Route
                  path={"/master-routes/create"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <CreateMasterRoute />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/master-routes/create/add-store"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <AddMasterRoute />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/master-routes/edit/:id"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <EditMasterRoute />
                    </RequirePermission>
                  }
                />
                <Route
                  path={"/master-routes/edit/:id/add-store"}
                  element={
                    <RequirePermission requiredPermission={permissionAdmin}>
                      <EditAddMasterRoute />
                    </RequirePermission>
                  }
                />
              </Route>

              {/* =====  Menu Auth */}
              <Route path={"/"} element={<LayoutAuth />}>
                <Route path={"login"} element={<Login />} />
                <Route path={"forgot-password"} element={<ForgotPassword />} />
                <Route path={"reset-password"} element={<ResetPassword />} />
                <Route path={"token-expire"} element={<TokenExpire />} />
                <Route path={"all-set"} element={<AllSet />} />
                <Route
                  path={"reset-successfully"}
                  element={<ResetSuccessfully />}
                />
                <Route path={"activation/:token"} element={<Activation />} />
              </Route>

              <Route path={"/changelog"} element={<ChangeLog />} />
              <Route
                path={"/delivery-partners"}
                element={<DeliveryPartner />}
              />
              <Route path={"/thankyou-signup"} element={<ThankYou />} />

              {/* =====  Menu Err */}
              <Route
                path={`${process.env.PUBLIC_URL}`}
                element={<ErrorPages />}
              >
                <Route path={"not-found"} element={<BadRequestError />} />
                <Route path={"unauthorized"} element={<UnauthorizedError />} />
                <Route path={"forbidden"} element={<ForbiddenError />} />
                <Route path={"not-found"} element={<NotFoundError />} />
                <Route
                  path={"server-error"}
                  element={<InternalServerError />}
                />
                <Route
                  path={"service-unavailable"}
                  element={<ServiceUnavailableError />}
                />
                ,
              </Route>

              <Route path="*" element={<InternalServerError />} />
            </Routes>
          </React.Suspense>
        </AuthenProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
