import { AxiosResponse } from "axios";
import axiosClient from "../../baseApi";

const basePath: any = {
  getAll: "/invoice/get-list",
  getInvoiceUrl: "/invoice/get-invoice-url",
  getExport: "/invoice/export-csv",
};

export const invoiceQueryKey: any = {
  getAllInvoice: "getAllInvoice",
  getInvoiceUrl: "getAllInvoiceUrlInvoice",
  getExportInvoice: "getExportInvoice",
};

export const getAll = (
  params: any,
  signal: any,
): Promise<AxiosResponse<any, any>> => {
  try {
    const res: any = axiosClient.get(basePath?.getAll, {
      params,
      signal,
    });
    return Promise.resolve(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInvoiceUrl = (
  invoiceNumber: string,
  uploadBy: number,
): Promise<AxiosResponse<any, any>> => {
  try {
    const params = {
      invoiceNumber,
      uploadBy,
    };
    const res: Promise<AxiosResponse<any, any>> = axiosClient.get(
      `${basePath.getInvoiceUrl}`,
      { params },
    );
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const getExport = (params: string): Promise<AxiosResponse<any, any>> => {
  try {
    const res: Promise<AxiosResponse<any, any>> = axiosClient.get(
      `${basePath.getExport}`,
      { params },
    );
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
