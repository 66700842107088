/* eslint-disable eqeqeq */
import CryptoJS from "crypto-js";
import { numberWithCommas } from "../components/Global/converse-comma";
import { getInvoiceUrl } from "../services/app-data/finances/apiInvoice";
import { getCreditUrl } from "../services/app-data/finances/apiCredit";
import moment from "moment";

export const STR_EMPTY: string = "";
export const STR_ADD_SUCCESS: string = "Added Successfully";
export const STR_UPDATE_SUCCESS: string = "Updated Successfully";
export const FORMAT_DATE: string = "YYYY-MM-DD";
export const FORMAT_DATE_WITH_TIME: string = "YYYY-MM-DD HH:mm:ss";

const secretKey: string = "eA8m58Lwkb";
interface Response {
  message: string;
}

export const START_OF_WEEK_MOMENT = moment()
  .startOf("week")
  .add(1, "day")
  .startOf("day");
export const END_OF_WEEK_MOMENT = moment()
  .endOf("week")
  .add(1, "day")
  .startOf("day");

export const START_OF_WEEK_DATE = moment()
  .startOf("week")
  .add(1, "day")
  .startOf("day")
  .toDate();
export const END_OF_WEEK_DATE = moment()
  .endOf("week")
  .add(1, "day")
  .endOf("day")
  .toDate();

export const START_END_OF_WEEK_DATE = [
  moment().startOf("week").add(1, "day").startOf("day").toDate(),
  moment().endOf("week").add(1, "day").endOf("day").toDate(),
];
export const START_TODAY_END_OF_WEEK_DATE = [
  moment().startOf("day").toDate(),
  moment().endOf("week").endOf("day").toDate(),
];
export const capitalizeFirstLetter = (string: string): string => {
  if (string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const moneyFormat = (
  price: number | string,
  hasPrefix: boolean,
): string => {
  const numericPrice = parseFloat(price as string);
  if (isNaN(numericPrice)) {
    return "Invalid price"; // or any other error handling
  }

  if (numericPrice < 0) {
    const newPrice = numericPrice.toString().replace("-", "");
    return `-${numberWithCommas(newPrice)}`;
  } else {
    return numberWithCommas(numericPrice.toString());
  }
};

export const moneyFormatWithDollarSince = (
  price: number | string,
  hasPrefix: boolean,
): string => {
  const numericPrice = parseFloat(price as string);
  if (isNaN(numericPrice)) {
    return "Invalid price"; // or other error handling
  }
  const dollarSign = hasPrefix ? "$" : "";
  if (numericPrice < 0) {
    // Remove the negative sign and format
    const formattedPrice = numberWithCommas(Math.abs(numericPrice).toString());
    return `-${dollarSign}${formattedPrice}`;
  } else {
    const formattedPrice = numberWithCommas(numericPrice.toString());
    return `${dollarSign}${formattedPrice}`;
  }
};

export const percentFormat = (percent: number | string): string => {
  if (percent !== null && percent !== undefined && percent !== "") {
    const floatValue = parseFloat(percent as string);
    if (isNaN(floatValue)) {
      return "Invalid input"; // or any other error handling
    }
    return floatValue.toFixed(2);
  } else {
    return "0.00";
  }
};

export const addCommas = (number: number | string): string => {
  const numericValue = parseFloat(number as string);
  if (isNaN(numericValue)) {
    return "Invalid input"; // or handle the error as needed
  }

  // Convert the number to a string
  const strNumber = numericValue.toString();

  // Split the string into integer and decimal parts
  const parts = strNumber.split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  // Add commas to the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted number
  return formattedInteger + decimalPart;
};

export const IsResponseSuccess = (response: Response): boolean => {
  return response.message === "Success";
};

export function MeterToMile(meter: number): string {
  const miles = meter * 0.000621371192;
  return miles.toFixed(1);
}

export function SecToHourMinute(sec: number): string {
  const duration = moment.duration(sec, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  if (hours < 1) {
    return `${minutes} min`;
  } else {
    const formattedHours = hours + (minutes > 0 ? `.${minutes}` : "");
    return `${formattedHours} hr`;
  }
}

export function UnitToCase(unit: number, unitPerCase: number): number | null {
  if (unitPerCase === 0) {
    return null;
  }
  return unit / unitPerCase;
}

export function UnitToPallet(
  unit: number,
  unitPerCase: number,
  casePerPallet: number,
): number | null {
  if (unitPerCase === 0 || casePerPallet === 0) {
    return null;
  }
  return unit / unitPerCase / casePerPallet;
}

export function EncryptedId(id: any): string {
  try {
    const encryptedValue = CryptoJS.AES.encrypt(
      id?.toString(),
      secretKey,
    ).toString();
    const sanitizedValue = encryptedValue.replace(/\//g, "_");
    return sanitizedValue;
  } catch (error) {
    console.error("Encryption failed:", error);
    return "";
  }
}

export function DecryptedId(id: any): string {
  try {
    const originalEncryptedValue = id?.toString().replace(/_/g, "/");
    const decrypted = CryptoJS.AES.decrypt(originalEncryptedValue, secretKey);
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) {
      throw new Error("Decryption failed");
    }
    return decryptedText;
  } catch (error) {
    console.error("Decryption failed:", error);
    return "";
  }
}

export const getInvoiceImageFromS3 = async (
  invoiceNumber: string,
  uploadBy: number = 1,
) => {
  try {
    const res = await getInvoiceUrl(invoiceNumber, uploadBy);
    const invoiceUrl = res?.data?.data?.invoice_url;
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    } else {
      console.error("Invoice URL not found");
    }
  } catch (err) {
    console.error("Error fetching invoice image:", err);
  }
};

export const getCreditImageFromS3 = async (creditNumber: string) => {
  try {
    const res = await getCreditUrl(creditNumber);
    const creditUrl = res?.data?.data?.credit_url;
    if (creditUrl) {
      window.open(creditUrl, "_blank");
    } else {
      console.error("Credit URL not found");
    }
  } catch (err) {
    console.error("Error fetching credit image:", err);
  }
};

export function ComparePercentage(first: number, second: number): number {
  try {
    if (second && second != 0) {
      return ((first - second) / second) * 100;
    } else {
      return 0;
    }
  } catch (e) {
    return 0;
  }
}

export function isValidDate(date: Date): boolean {
  if (Object.prototype.toString.call(date) !== "[object Date]") {
    return false;
  }
  return !isNaN(date.getTime());
}
